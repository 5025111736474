import { Dispatch, FC, SetStateAction, useState, ChangeEvent } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useTranslation } from "react-i18next"
import { usePostCancelOrderMutation, useReturnOrderToWorkMutation } from "../../../redux/api/content"
import TextArea from "../../Assets/TextArea/TextArea"
import Button from "../../Assets/Button/Button"
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize"
import styles from "./OrderCallbackModal.module.scss"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  orderId: string
  delFromList?: (orderId: string) => void
  callbackClose: () => void
  layout?: "history" | "active" | "coordination"
  className?: string
}
const OrderCallbackModal: FC<Props> = ({ open, setOpen, orderId, delFromList, callbackClose, layout, className }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [cancelOrder, { isLoading: isLoadingCancel }] = usePostCancelOrderMutation()
  const [returnOrder, { isLoading: isLoadingReturn }] = useReturnOrderToWorkMutation()

  const [declineOrderText, setDeclineOrderText] = useState("")

  const handleSubmit = () => {
    // Удаления из списка текущих заявок
    if (delFromList) delFromList(orderId ?? "")
    setOpen(false)
    callbackClose()
  }

  const declineOrder = () => {
    if (layout === "history") {
      returnOrder({ id: orderId, reason: declineOrderText })
        .unwrap()
        .then(() => handleSubmit())
        .catch((e) => console.error(e))
      return
    }
    if (orderId) {
      cancelOrder({ id: orderId, reason: declineOrderText })
        .unwrap()
        .then(() => handleSubmit())
        .catch((e) => console.error(e))
    }
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      size={"sm"}
      header={<h2 className={"modal__title"}>{layout === "active" ? t("confirmCancellation") : t("returnToWork")}</h2>}
      className={className}
      isCloseBtn={isDesktop}
    >
      <p className={styles.txt}>{layout === "active" ? t("getReasonCancelOrder") : t("getReason")}</p>
      <TextArea
        placeholder={layout === "history" ? t("description") : t("enterComment")}
        value={declineOrderText}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDeclineOrderText(e.target.value)}
        className={clsx(
          !isDesktop && layout === "active" && styles["active-textarea-type"],
          !isDesktop && layout === "history" && styles["history-textarea-type"],
        )}
      />
      <div className={styles["btn-container"]}>
        {!isDesktop && (
          <Button
            txt={t("back")}
            mode={"gray"}
            onClick={() => setOpen(false)}
            className={clsx(styles.btn, styles["back-btn"])}
          />
        )}
        <Button
          txt={t("confirmBtn")}
          mode={isDesktop ? "gray" : layout === "active" ? "warning" : undefined}
          onClick={declineOrder}
          isLoading={isLoadingCancel || isLoadingReturn}
          className={styles.btn}
          disabled={!declineOrderText}
        />
      </div>
    </ModalPortal>
  )
}

export default OrderCallbackModal
