import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import { MouseEvent, ReactNode } from "react"
import styles from "./BtnObject.module.scss"

interface Props {
  type?: "button" | "div"
  txt?: string
  onClick?: () => void
  iconBack?: boolean
  error?: any
  mode?: "white"
  disabled?: boolean
  className?: string
}

interface BtnWrapProps {
  className?: string
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
  type?: "button" | "div"
  mode?: "white"
  disabled?: boolean
  children: JSX.Element | JSX.Element[] | ReactNode
}

const BtnObjectWrap = ({ className, onClick, type = "button", mode, disabled, children, ...props }: BtnWrapProps) => {
  if (type === "button") {
    return (
      <button
        type={type}
        className={className}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          if (onClick) onClick(e)
        }}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )
  }
  return (
    <div
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        if (onClick) onClick(e)
      }}
      className={className}
      {...props}
    >
      {children}
    </div>
  )
}

const BtnObject = ({ type = "button", txt, onClick, iconBack, error, mode, disabled, className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <BtnObjectWrap
      type={type}
      onClick={onClick}
      className={clsx(styles.btn, error && "error", mode && styles["btn--" + mode], className)}
      disabled={disabled}
    >
      <img src="/img/object_ico.svg" alt="" className={styles.icon} />
      <span className={styles.content}>
        <span className={styles.txt}>{t(txt ? "selectedObject" : "selectObj")}</span>
        {txt}
      </span>
      {iconBack && <ArrowRightIcon className={styles.iconBack} />}
      <span className={styles.error}>{error && t(error)}</span>
    </BtnObjectWrap>
  )
}

export default BtnObject
