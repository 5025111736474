export const FilterIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33398 8L3.33398 2.66667" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M12.666 13.334L12.666 12.0007" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M3.33398 13.334L3.33398 10.6673" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M12.666 8L12.666 2.66667" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M8 4.66602L8 2.66602" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M8 13.334L8 8.00065" stroke="#4B5058" strokeWidth="1.4" strokeLinecap="round" />
      <ellipse
        cx="3.33333"
        cy="9.33333"
        rx="1.33333"
        ry="1.33333"
        stroke="#4B5058"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <ellipse
        cx="7.99935"
        cy="5.99935"
        rx="1.33333"
        ry="1.33333"
        stroke="#4B5058"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <ellipse
        cx="12.6673"
        cy="9.99935"
        rx="1.33333"
        ry="1.33333"
        stroke="#4B5058"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  )
}
