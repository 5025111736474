import { FC, useMemo, useRef } from "react"
import { GetUserAvatar } from "../GetUserAvatar/GetUserAvatar"
import { IAssigneeShort } from "../../../types/content"
import clsx from "clsx"
import s from "./UserAvatars.module.scss"

interface Props {
  className?: string
  items?: IAssigneeShort | IAssigneeShort[]
  /* показывать/скрывать имя, если list.length === 1 */
  showName?: boolean
  limit?: number
}

const UserAvatars: FC<Props> = ({ className, items, showName = false, limit = 5 }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const list = useMemo(() => {
    if (!items) return []

    return Array.isArray(items) ? items : [items]
  }, [items])

  if (!list?.length) return null

  return (
    <>
      <div ref={containerRef} className={clsx(s.container, className)}>
        {list.slice(0, limit).map((el, i) => {
          return (
            <div className={clsx(s.el)} key={i}>
              <div className={clsx(s.el__avatar)}>
                <GetUserAvatar name={el.name} avatar={el.avatar_id} size={"sm"} />
                {i === limit - 1 && list.length > limit && (
                  <div className={clsx(s.el__total)}>{list.length - limit}</div>
                )}
              </div>
              {list.length === 1 && showName && <span>{el.name}</span>}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default UserAvatars
