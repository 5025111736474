import { api } from "./index"
import { IArticle, ITag } from "../../types/content"

export const articlesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<IArticle[], { search?: string; tags?: string[] } | void>({
      query: (data) => {
        const searchQuery = data?.search ? `?${new URLSearchParams({ search: data.search }).toString()}` : ""
        let tagsQuery = ""
        if (data?.tags) {
          data.tags.forEach((tag, index) => {
            if (!tagsQuery && !searchQuery) tagsQuery = "?"
            if ((searchQuery && !tagsQuery) || (tagsQuery && tagsQuery !== "&" && tagsQuery !== "?")) {
              tagsQuery = tagsQuery + "&"
            }
            tagsQuery = tagsQuery + `tags[${index}][id]=${tag}`
          })
        }
        const queryString = searchQuery || tagsQuery ? "" + searchQuery + tagsQuery : ""
        return {
          url: `/public/articles${queryString}`,
          method: "GET",
        }
      },
    }),
    // список популярных статей (первые 18 шт) в поле 'popular' и список новых статей (первые 9 шт) в поле 'new'
    getPopularArticles: builder.query<
      {
        new: IArticle[]
        popular: IArticle[]
      },
      void
    >({
      query: () => ({
        url: "/public/articles/main",
        method: "GET",
      }),
    }),
    getArticle: builder.query<IArticle, string>({
      query: (id) => ({
        url: `/public/articles/${id}`,
        method: "GET",
      }),
    }),
    getFavoriteArticles: builder.query<IArticle[], { limit?: number; offset?: number } | void>({
      query: (data) => {
        const limitKey = data?.limit ? `?limit=${data.limit}` : ""
        const queryString =
          data?.limit && data?.offset
            ? `${limitKey}&offset=${data.offset}`
            : data?.offset
            ? `?offset=${data.offset}`
            : ""
        return {
          url: `/public/articles/favorites${queryString}`,
          method: "GET",
        }
      },
    }),
    setFavoriteArticle: builder.mutation<{ status: "out_favorites" | "in_favorites" }, string>({
      query: (id) => ({
        url: `/public/articles/favorites/${id}`,
        method: "POST",
      }),
    }),
    getArticlesTags: builder.query<
      { aTags: ITag[]; bIsEnd: boolean; iCount: number },
      { limit?: number; offset?: number; keywords?: string } | void
    >({
      query: () => ({
        url: `/public/tags/selectlist/articles?onlyWithUsage=1`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetArticlesQuery,
  useLazyGetArticlesQuery,
  useGetPopularArticlesQuery,
  useGetArticleQuery,
  useLazyGetArticleQuery,
  useGetFavoriteArticlesQuery,
  useSetFavoriteArticleMutation,
  useGetArticlesTagsQuery,
} = articlesApi
