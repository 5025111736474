import {getCookie} from "./cookies";
import {STAQ_VERSION_COOKIE} from "./constants";
import {getApiUrl} from "./getApiUrl";

export function registerSW() {
    console.log('getApiUrl ',getApiUrl())
    if ('serviceWorker' in navigator) {
        let key, authSecret

        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js')
                .then(function (registration) {
                    return registration.pushManager.getSubscription().then(function (subscription) {
                        if (subscription) {
                            return subscription
                        }

                        if (getCookie(STAQ_VERSION_COOKIE) !== String(process.env.REACT_APP_PUBLIC_STAQ_VERSION)) {
                            registration.update()
                        }

                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: process.env.NEXT_PUBLIC_PUSH_SERVER_KEY,
                        })
                    })
                })
                .then(function (subscription) {
                    // Получаем public key для пользователя.
                    const rawKey: any = subscription.getKey ? subscription.getKey('p256dh') : '';
                    key = rawKey
                        ? btoa(String.fromCharCode(...new Uint8Array(rawKey)))
                        : '';
                    const rawAuthSecret: any = subscription.getKey ? subscription.getKey('auth') : '';
                    authSecret = rawAuthSecret
                        ? btoa(String.fromCharCode(...new Uint8Array(rawAuthSecret)))
                        : '';

                    // Отправляем детали о подписке на сервер
                    fetch(
                        `${getApiUrl()}/public/login/sub-push`,
                        {
                            method: 'post',
                            credentials: 'include',
                            headers: {
                                'Content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                endpoint: subscription.endpoint,
                                publicKey: key,
                                authToken: authSecret,
                                contentEncoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0],
                            }),
                        }
                    )
                })
        });
    }
}