import Button from "../Assets/Button/Button"
import clsx from "clsx"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { IEmployee } from "../../types/content"
import { useLazyGetDirectoryQuery } from "../../redux/api/content"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import Search from "../Search/Search"
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll"
import ScrollBlock from "../Assets/ScrollBlock"
import { useTranslation } from "react-i18next"
import Employee from "../Employees/Employee/Employee"
import { CheckIcon } from "../../icons/CheckIcon"
import _debounce from "lodash/debounce"
import { TDirectory } from "../../types/orderTypes"
import useWindowSize from "../../hooks/useWindowSize"
import styles from "./SelectReference.module.scss"

interface Props {
  type: TDirectory
  onSubmit: (data: { id: string; name: string; avatar_id?: string }[]) => void
  list?: { id: string; name: string; avatar_id?: string }[]
  isTop?: boolean
  className?: string
}

const LIMIT_ITEMS = 20

const ListLoader = () => {
  return (
    <div className={clsx("employees__grid", styles.grid, styles["grid--is-skeleton"])}>
      {[...Array(4)].map((_item, index) => (
        <div key={index} className={clsx(styles.skeleton, styles["skeleton--sm"], "skeletonBlock")} />
      ))}
    </div>
  )
}

const SelectReference = ({ onSubmit, list, type, isTop, className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [getDirectory, { isFetching, isLoading, isUninitialized }] = useLazyGetDirectoryQuery()

  const user = useAppSelector(selectUser)

  const [dataState, setDataState] = useState<{ id: string; name: string; avatar_id?: string }[]>([])
  const [searchChat, setSearchChat] = useState<string>("")
  const [items, setItems] = useState<IEmployee[]>([])
  const [offset, setOffset] = useState<number>(0)
  const loaderRef = useRef<HTMLDivElement>(null)
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [isAllowFetched, setAllowFetched] = useState<boolean>(false)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [radioChecked, setRadioChecked] = useState<string>("")

  useEffect(() => {
    setRadioChecked(dataState && dataState?.length > 0 ? dataState[0].id : "")
  }, [dataState])

  const infiniteScroll = useInfiniteScroll<HTMLDivElement>(
    async () => {
      if (isListEnd || isFetching) return
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
        void fetchData(false)
      })
    },
    { distance: 10, isScrollPlugin: true },
  )

  useEffect(() => {
    if (type === "users") {
      getDirectory({ type: type, limit: LIMIT_ITEMS }).then((res) => {
        if (res?.data) {
          setItems(() => res.data?.aObjects || [])
          setListEnd(res.data.bIsEnd)
          setAllowFetched(true)
        }
      })
    }
  }, [type])

  const fetchData = async (isStart?: boolean) => {
    if (isListEnd) return
    if (isFetching || !isAllowFetched) return
    setOffset((prev) => (isStart ? 0 : prev + LIMIT_ITEMS))
    setAllowFetched(false)
    await getDirectory({ type: type, limit: LIMIT_ITEMS, search: searchChat, offset: isStart ? 0 : offset }).then(
      ({ data }) => {
        if (data?.aObjects) {
          setItems((prevItems) => {
            return !isStart && offset ? [...prevItems, ...data.aObjects] : data?.aObjects || []
          })
          setListEnd(data.bIsEnd)
        }
      },
    )
    setSearchLoading(false)
    setTimeout(() => {
      setAllowFetched(true)
    }, 0)
  }

  useEffect(() => {
    if (!list?.length) return
    setDataState(list)
  }, [list])

  const handleSubmit = () => {
    onSubmit(dataState)
  }

  const handleReset = () => {
    setDataState([])
  }

  const dataList = useMemo(() => {
    if (!items?.length) return null
    return items.map((el) => {
      if (el.id === user?.id) return null
      return (
        <label
          key={el.id}
          className={styles.label}
          onClick={() => {
            setDataState([{ id: el.id, name: el.name, avatar_id: el?.avatar_id }])
          }}
        >
          <Employee {...el} highlightTxt={searchChat} className={clsx(styles.item, className)} size={"sm"} />
          <div className={styles.inputWrap}>
            <input
              type={"radio"}
              hidden
              name={"radio-" + el.id}
              checked={radioChecked === el.id}
              onChange={() => setRadioChecked(el.id)}
            />
            {radioChecked === el.id && <CheckIcon />}
            <div className={styles.itemIco} />
          </div>
        </label>
      )
    })
  }, [items, searchChat, radioChecked, user?.id])

  useEffect(() => {
    if (isUninitialized) return
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        if (isFetching || isSearchLoading || !isAllowFetched) return
        void fetchData(false)
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isUninitialized, isFetching, isLoading, isSearchLoading, isAllowFetched, dataList])

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (isUninitialized) return
      if (!isAllowFetched) return
      if (isSearchLoading) return
      setSearchLoading(true)
      if (type === "users") {
        getDirectory({ type: type, limit: LIMIT_ITEMS, search: str, offset: 0 }).then(({ data }) => {
          if (data?.aObjects) {
            setItems(() => data?.aObjects || [])
            setSearchLoading(false)
            setListEnd(data.bIsEnd)
            setTimeout(() => {
              setAllowFetched(true)
            }, 0)
          }
        })
      }
    }, 500),
    [isUninitialized, isAllowFetched, isSearchLoading, type],
  )

  useEffect(() => {
    setOffset(0)
    debounceFn(searchChat)
  }, [searchChat])

  return (
    <div className={clsx(styles.wrap, isTop && styles["wrap--is-top"], className)}>
      <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />

      <ScrollBlock
        //@ts-ignore
        ref={infiniteScroll.ref}
        hideTracksWhenNotNeeded
        scrollBarSize={4}
        autoHeightMax={isDesktop ? 145 : 340}
      >
        {!isUninitialized && !isSearchLoading && (
          <>
            {dataList && dataList?.length > 0 ? (
              <div className={clsx("employees__grid", styles.grid)}>{dataList}</div>
            ) : (
              <>
                {!isSearchLoading && !isFetching && !isLoading && (
                  <div className={styles.nothing}>{t("noEmployeesYet")}</div>
                )}
              </>
            )}
          </>
        )}

        {dataList?.length && (infiniteScroll.isLoading || isFetching) && <ListLoader />}
        {isFetching && !dataList?.length && <ListLoader />}
      </ScrollBlock>

      <footer className={styles.footer}>
        <Button
          txt={t(isDesktop ? "reset" : "back")}
          mode={"gray"}
          size={"xsm"}
          className={styles.btnReset}
          onClick={handleReset}
        />
        <Button txt={t("apply")} size={"xsm"} className={styles.btnSubmit} onClick={handleSubmit} />
      </footer>
    </div>
  )
}
export default SelectReference
