import { useState, FC } from "react"
import NoFindModal from "../Modals/NoFindModal/NoFindModal"
import { useTranslation } from "react-i18next"
import { TechnicalObject } from "../../types/orderTypes"
import { usePostReqForNewProductMutation } from "../../redux/api/main"
import { Form, Formik } from "formik"
import { addActiveOrdersCount, selectIsAllowNewServiceCategory, selectUser } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import { useLocation, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import SuccessModal from "../Modals/SuccessModal/SuccessModal"
import Button from "../Assets/Button/Button"
import clsx from "clsx"
import styles from "./NoFindBtn.module.scss"

interface initialValues {
  technical_object_id?: string
  category_id?: string
  text: string
}

interface Props {
  isLoading?: boolean
}

const NoFindBtn: FC<Props> = ({ isLoading }) => {
  const [openModal, setOpenModal] = useState(false)
  //TODO: нужен ли объект?
  const [selectObjModal, setSelectObjModal] = useState(false)
  const [techObjects, setTechObjects] = useState<TechnicalObject[]>([])
  const [successModal, setSuccessModal] = useState(false)
  const [successModalNum, setSuccessModalNum] = useState<number>(0)
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  // const [getProductInfo] = useGetTechObjectListMutation()
  const [setNewProduct] = usePostReqForNewProductMutation()
  const user = useAppSelector(selectUser)
  const { pathname } = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const isAllowNewServiceCategory = useAppSelector(selectIsAllowNewServiceCategory)

  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")
  const isProductsPage = pathname.includes("products")
  const isCategoryPage = pathname.includes("category")

  const initialValuesForm: initialValues = {
    // technical_object_id: "",
    text: "",
  }
  if (isObjectPage) initialValuesForm.technical_object_id = params?.object_id
  if (isObjectPage && isCategoryPage && isAllowNewServiceCategory) initialValuesForm.category_id = params.category_id
  if (isProductsPage && isAllowNewServiceCategory) initialValuesForm.category_id = params?.category_id

  const submitForm = async (values: initialValues, actions: any) => {
    actions.setSubmitting(true)
    try {
      await setNewProduct(values)
        .unwrap()
        .then((res) => {
          setSuccessModalNum(res?.data?.number ?? 0)
          actions.resetForm()
          actions.setSubmitting(false)
          setOpenModal(false)
          setSuccessModal(true)
          dispatch(addActiveOrdersCount())
        })
    } catch (e) {
      console.error(e)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Button
        size={"md"}
        className={clsx("NoFindBtn", styles.noFindBtn, isLoading && "selectBack")}
        txt={isLoading ? "" : t("noFindService")}
        onClick={() => {
          if (!user?.id) return
          setOpenModal(true)
        }}
      />

      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => submitForm(values, actions)}
      >
        <Form>
          <NoFindModal open={openModal} setOpen={setOpenModal} />
        </Form>
      </Formik>

      <SuccessModal
        modalTitle={`${t("cancelOrderApplication")} #${successModalNum} ${t("openOrderPublished")}`}
        modalText={`${t("openOrderComment")}`}
        setOpen={setSuccessModal}
        open={successModal}
      />
    </>
  )
}

export default NoFindBtn
