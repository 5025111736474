import ReactPortal from "./ReactPortal"
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import { CrossIcon } from "../../../icons/CrossIcon"
import styles from "./ModalPortal.module.scss"

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>> | (() => void)
  onClose?: () => void
  name?: string
  children?: ReactNode
  header?: ReactNode
  closeOutside?: (param: HTMLElement) => boolean
  isLoading?: boolean
  className?: string
  size?: "sm" | "lg"
  bodyNoPadding?: boolean
  isCloseBtn?: boolean
}

const ModalPortal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onClose,
  name = "modal",
  children,
  header,
  isLoading = false,
  className,
  size,
  bodyNoPadding,
  isCloseBtn = true,
}) => {
  const [isModalExist, setModalExist] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalLoading, setModalLoading] = useState<boolean>(false)

  const modalRef = useRef(null)

  const { blockScroll, allowScroll } = useScrollBlock()

  useEffect(() => {
    if (isOpen) {
      blockScroll()
      setModalExist(true)
      setTimeout(() => {
        setModalOpen(true)
      }, 0)
    } else {
      if (isModalExist) allowScroll()
      setModalOpen(false)
      setModalExist(false)
      // setTimeout(() => {
      //   setModalExist(false)
      // }, 200)
    }
  }, [isOpen])

  useEffect(() => {
    setModalLoading(isLoading)
  }, [isLoading])

  const closeModal = () => {
    const modals = document.querySelectorAll(".modal")

    setModalOpen(false)
    setModalExist(false)
    if (modals?.length < 2) allowScroll()
    if (onClose) onClose()
    setIsOpen(false)
    // setTimeout(() => {
    //   setModalExist(false)
    //   if (modals?.length < 2) allowScroll()
    //   if (onClose) onClose()
    //   setIsOpen(false)
    // }, 200)
  }

  const innerRef = useRef(null)

  if (!isModalExist) return null

  return (
    <ReactPortal portalID={name}>
      <div
        ref={modalRef}
        className={clsx(
          "modal",
          styles.modal,
          size && styles["modal--" + size],
          isModalOpen && styles["modal--is-active"],
          isModalLoading && styles["modal--is-loading"],
          className && className,
        )}
      >
        <div className={clsx("modal__overlay", styles.overlay)} onClick={closeModal} />
        <div className={clsx("modal__inner", styles.inner)} ref={innerRef}>
          {header ? (
            <header className={clsx("modal__header", styles.header)}>
              {header}
              {isCloseBtn && (
                <button className={clsx("modal__close-btn", styles.btn)} onClick={closeModal}>
                  <CrossIcon />
                </button>
              )}
            </header>
          ) : isCloseBtn ? (
            <button className={clsx("modal__close-btn", styles.btn)} onClick={closeModal}>
              <CrossIcon />
            </button>
          ) : null}

          <div className={clsx("modal__body", styles.body, bodyNoPadding && styles["body--no-padding"])}>
            {isModalLoading ? <div className={clsx("skeletonBlock")} style={{ height: "300px" }} /> : <>{children}</>}
          </div>
        </div>
      </div>
    </ReactPortal>
  )
}

export default ModalPortal
