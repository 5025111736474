import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import { useGetCategoriesQuery } from "../../redux/api/main"
import OrderLayoutHeader from "./OrderLayoutHeader"
import OrderLayoutMain from "./OrderLayoutMain"
import orderServiceStyles from "../OrderService/OrderService.module.scss"
import { useGetServiceOrderQuery } from "../../redux/api/content"
import clsx from "clsx"
import styles from "./OrderLayout.module.scss"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useWindowSize from "../../hooks/useWindowSize"
import TitleBack from "../TitleBack/TitleBack"

const OrderLayout = ({ id }: { id: string }) => {
  const navigate = useNavigate()
  const { isDesktop } = useWindowSize()

  const { data: order, isLoading: isLoadingProduct, isUninitialized } = useGetServiceOrderQuery(id)
  const { data: categories, isLoading: isLoadingList, isUninitialized: isUninitializedList } = useGetCategoriesQuery()

  useEffect(() => {
    if (isUninitialized && isUninitializedList && !order) {
      navigate("/")
    }
  }, [isUninitialized, isUninitializedList, order])

  return (
    <div className={clsx(styles.orderLayout)}>
      {isDesktop ? (
        <>
          {isLoadingList || isLoadingProduct ? (
            <div className={clsx("skeletonBlock", orderServiceStyles.linkLoader)} />
          ) : (
            <LocalizedLink to={`/category/${order?.category_id}`} className={orderServiceStyles.link}>
              <ArrowLeftBoldIcon />
              {categories?.find((category) => category.id === order?.category_id)?.name}
            </LocalizedLink>
          )}
        </>
      ) : (
        <TitleBack
          title={categories?.find((category) => category.id === order?.category_id)?.name}
          className={styles.titleBack}
        />
      )}

      {isLoadingProduct ? (
        <>
          <div className={clsx("skeletonBlock", styles.headerLoader)} />
          <div className={clsx("skeletonBlock", styles.mainLoader)} />
        </>
      ) : (
        <>
          {order?.id && (
            <>
              <OrderLayoutHeader order={order} />
              <OrderLayoutMain order={order} className="paper-layout-main" />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default OrderLayout
