import { FC, useMemo, useState } from "react"
import clsx from "clsx"
import { IEmployee } from "../../../types/content"
import { ChatIcon } from "../../../icons/ChatIcon"
import { useCreateChatMutation } from "../../../redux/api/chat"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectUser } from "../../../redux/slice/auth"
import { setMessagesModalFirstChat, setMessagesModalIsOpen } from "../../../redux/slice/modals"
import { useTranslation } from "react-i18next"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./Employee.module.scss"

interface Props extends IEmployee {
  className?: string
  highlightTxt?: string
  size?: "sm"
  onClick?: (userID: string, name: string, avatar_id?: string) => void
}

const Employee: FC<Props> = ({
  className,
  size,
  onClick,
  highlightTxt,
  id,
  name,
  avatar_id,
  position,
  email,
  phone_number,
  add_phone_number,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const user = useAppSelector(selectUser)
  const [createChat, { isLoading: isLoadingCreateChat }] = useCreateChatMutation()
  const dispatch = useAppDispatch()

  const [chatId, setChatId] = useState<string>("")

  const userName = useMemo(() => {
    if (!highlightTxt) return name
    const currentSearch = highlightTxt.toLowerCase()
    const index = name.toLowerCase().indexOf(currentSearch)
    let searchedName = name
    if (index >= 0) {
      searchedName =
        searchedName.substring(0, index) +
        `<span class=${styles.highlight}>` +
        searchedName.substring(index, index + currentSearch.length) +
        "</span>" +
        searchedName.substring(index + currentSearch.length)
      return searchedName
    } else {
      return name
    }
  }, [name, highlightTxt])

  const handleOpenChat = () => {
    createChat({ type: "users", id: id })
      .unwrap()
      .then((res) => {
        dispatch(setMessagesModalFirstChat(res))
        dispatch(setMessagesModalIsOpen(true))
      })
  }

  return (
    <>
      <div
        className={clsx(styles.item, size && styles["item--" + size], className)}
        onClick={() => {
          if (onClick) onClick(id, name, avatar_id)
        }}
      >
        <div className={styles.content}>
          <p className={styles.name} dangerouslySetInnerHTML={{ __html: userName }} />

          {size !== "sm" && (
            <>
              {position && <p className={styles.position}>{position}</p>}
              {email && (
                <a href={"mailto:" + email} className={styles.link}>
                  {email}
                </a>
              )}
              <div className={styles.phoneWrap}>
                {phone_number && (
                  <a href={"tel:" + phone_number} className={styles.link}>
                    {phone_number}
                  </a>
                )}
                {phone_number && add_phone_number && ", "}
                {add_phone_number && (
                  <a href={"tel:" + add_phone_number} className={styles.link}>
                    {add_phone_number}
                  </a>
                )}
              </div>
              {id && user?.id !== id && (
                <button
                  disabled={isLoadingCreateChat}
                  type={"button"}
                  className={clsx(styles.btn, isLoadingCreateChat && styles["btn--is-loading"])}
                  onClick={handleOpenChat}
                >
                  {isLoadingCreateChat && <BtnLoader />}
                  <ChatIcon />
                  {t("write")}
                </button>
              )}
            </>
          )}
        </div>

        <GetUserAvatar avatar={avatar_id} name={name} size={"lg"} className={styles.avatar} />
      </div>
    </>
  )
}

export default Employee
