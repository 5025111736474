// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoordinationsFilters_title-back__uRJrT {
  margin-bottom: 16px;
}

.CoordinationsFilters_search__aDtbo {
  margin-bottom: 24px;
}
@media (min-width: 640px) {
  .CoordinationsFilters_search__aDtbo {
    margin-bottom: 31px;
  }
}

.CoordinationsFilters_coordination-container__ibVFW {
  margin-bottom: 32px;
}
.CoordinationsFilters_coordination-container__ibVFW ~ .CoordinationsFilters_coordination-container__ibVFW {
  margin-bottom: 0;
}
.CoordinationsFilters_coordination-container__ibVFW .CoordinationsFilters_title__mIl7e {
  margin-bottom: 12px;
  font-weight: 600;
}

.CoordinationsFilters_list-container__veIqZ {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.CoordinationsFilters_skeleton__23Du\\+,
.CoordinationsFilters_item__9lC4D {
  margin-bottom: 12px;
}
.CoordinationsFilters_skeleton__23Du\\+:last-child,
.CoordinationsFilters_item__9lC4D:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .CoordinationsFilters_skeleton__23Du\\+,
  .CoordinationsFilters_item__9lC4D {
    margin-bottom: 0;
  }
}

.CoordinationsFilters_skeleton__23Du\\+ {
  width: 100%;
  height: 164px;
  border-radius: 12px;
}
.CoordinationsFilters_skeleton--sm__xNt9F {
  height: 34px;
  margin-bottom: 2px;
}

.CoordinationsFilters_nothing__qcIUZ {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 114%;
  margin: auto;
  width: 100%;
}
@media (min-width: 1200px) {
  .CoordinationsFilters_nothing__qcIUZ {
    margin: 20px auto;
    position: initial;
  }
}

.CoordinationsFilters_filters-container__Qzate {
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .CoordinationsFilters_filters-container__Qzate {
    margin-bottom: 0;
  }
}

.CoordinationsFilters_members-filter__0LSSY .btn__text-container {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Coordinations/CoordinationsFilters/CoordinationsFilters.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AACE;EAHF;IAII,mBAAA;EAEF;AACF;;AAGE;EACE,mBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,mBAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHF;;AAMA;;EAEE,mBAAA;AAHF;AAKE;;EACE,gBAAA;AAFJ;AAKE;EARF;;IASI,gBAAA;EADF;AACF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,YAAA;EACA,kBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAFF;AAIE;EARF;IASI,iBAAA;IACA,iBAAA;EADF;AACF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;AADF;AAGE;EANF;IAOI,gBAAA;EAAF;AACF;;AAKI;EACE,aAAA;EACA,mBAAA;AAFN","sourcesContent":[".title-back {\n  margin-bottom: 16px;\n}\n\n.search {\n  margin-bottom: 24px;\n\n  @media (min-width: 640px) {\n    margin-bottom: 31px;\n  }\n}\n\n.coordination-container {\n\n  & {\n    margin-bottom: 32px;\n  }\n  \n  & ~ & {\n    margin-bottom: 0;\n  }\n\n  .title {\n    margin-bottom: 12px;\n    font-weight: 600;\n  }\n}\n\n.list-container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.skeleton,\n.item {\n  margin-bottom: 12px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 0;\n  }\n}\n\n.skeleton {\n  width: 100%;\n  height: 164px;\n  border-radius: 12px;\n\n  &--sm {\n    height: 34px;\n    margin-bottom: 2px;\n  }\n}\n\n.nothing {\n  text-align: center;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 114%;\n  margin: auto;\n  width: 100%;\n\n  @media (min-width: 1200px) {\n    margin: 20px auto;\n    position: initial;\n  }\n}\n\n.filters-container {\n  margin-bottom: 16px;\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n\n  @media (max-width: 640px) {\n    margin-bottom: 0;\n  }\n}\n\n.members-filter {\n  :global {\n    .btn__text-container {\n      display: flex;\n      align-items: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title-back": `CoordinationsFilters_title-back__uRJrT`,
	"search": `CoordinationsFilters_search__aDtbo`,
	"coordination-container": `CoordinationsFilters_coordination-container__ibVFW`,
	"title": `CoordinationsFilters_title__mIl7e`,
	"list-container": `CoordinationsFilters_list-container__veIqZ`,
	"skeleton": `CoordinationsFilters_skeleton__23Du+`,
	"item": `CoordinationsFilters_item__9lC4D`,
	"skeleton--sm": `CoordinationsFilters_skeleton--sm__xNt9F`,
	"nothing": `CoordinationsFilters_nothing__qcIUZ`,
	"filters-container": `CoordinationsFilters_filters-container__Qzate`,
	"members-filter": `CoordinationsFilters_members-filter__0LSSY`
};
export default ___CSS_LOADER_EXPORT___;
