import { FC } from "react"
import clsx from "clsx"
import styles from "./TextInput.module.scss"
import { CrossIcon } from "../../../icons/CrossIcon"

interface Props {
  className?: string
  value: string
  onChange?: (val?: string) => void
  type?: "string" | "time"
  customClear?: () => void
  icon?: JSX.Element
  [key: string]: any
}

const TextInput: FC<Props> = ({ className, value, onChange, icon, customClear, type = "string", ...props }) => {
  return (
    <div className={styles.TextInputContainer}>
      <input
        value={value}
        type={type}
        onChange={(e) => {
          if (onChange) onChange(e.target.value)
        }}
        className={clsx(styles.TextInput, value && styles.TextInput__filled, className)}
        {...props}
      />
      {customClear && value && (
        <span onClick={customClear} className={clsx(styles["icon"], styles["close-icon"])}>
          {<CrossIcon />}
        </span>
      )}
      {icon && <>{((!!customClear && !value) || !customClear) && <span className={styles["icon"]}>{icon}</span>}</>}
    </div>
  )
}

export default TextInput
