import clsx from "clsx"

export const QrAreaIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={clsx(className)}
      width="246"
      height="245"
      viewBox="0 0 246 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.5C0.5 4.70101 5.20101 0 11 0H35V5H11C7.96243 5 5.5 7.46243 5.5 10.5V34.5H0.5V10.5ZM235 5H211V0H235C240.799 0 245.5 4.70101 245.5 10.5V34.5H240.5V10.5C240.5 7.46243 238.038 5 235 5ZM5.5 210.5V234.5C5.5 237.538 7.96243 240 11 240H35V245H11C5.20101 245 0.5 240.299 0.5 234.5V210.5H5.5ZM245.5 210.5V234.5C245.5 240.299 240.799 245 235 245H211V240H235C238.038 240 240.5 237.538 240.5 234.5V210.5H245.5Z"
      />
    </svg>
  )
}
