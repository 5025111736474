import BtnObject from "../Assets/BtnObject/BtnObject"
import Paper from "../Assets/Paper/Paper"
import { IServiceOrder } from "../../types/orderTypes"
import moment from "moment"
import { getApiUrl } from "../../utils/getApiUrl"
import { useTranslation } from "react-i18next"
import PreviewFile from "../Assets/PreviewFile/PreviewFile"
import Button from "../Assets/Button/Button"
import styles from "./OrderLayout.module.scss"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import { useState } from "react"

const OrderLayoutMain = ({ order, className }: { order: IServiceOrder; className?: string }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <Paper className={className}>
        <h2 className={styles.mainTitle}>{order.name}</h2>

        {order.description && <p className={styles.description}>{order.description}</p>}

        {order.TechnicalObject && <BtnObject type={"div"} txt={order.TechnicalObject} className={styles.object} />}

        <p className={styles.item}>
          <span className={styles.itemTxt}>Номер заявки</span>
          <span className={styles.itemDots} />
          <span className={styles.itemVal}>{order.number}</span>
        </p>

        <p className={styles.item}>
          <span className={styles.itemTxt}>Время создания заявки</span>
          <span className={styles.itemDots} />
          <span className={styles.itemVal}>{moment(order.created_at).format("DD.MM.YYYY kk:mm")}</span>
        </p>

        {order.options.map((item, ind) => {
          switch (item.type) {
            case "information":
              return (
                <div key={ind} className={styles.block}>
                  <h3>{item.name}</h3>
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.description }} />
                  {item.photos?.length > 0 && (
                    <div className={styles.imgGrid}>
                      {item.photos.map((el, index) => (
                        <div key={index} className={styles.imgWrap}>
                          <img src={getApiUrl() + "/public/photo/" + el} alt="" />
                        </div>
                      ))}
                    </div>
                  )}
                  {item.attachments_info.length > 0 && (
                    <div className={styles.fileGrid}>
                      {item.attachments_info.map((el) => (
                        <PreviewFile key={el.id} {...el} />
                      ))}
                    </div>
                  )}
                </div>
              )
            case "directory":
            case "list":
              return (
                <p key={ind} className={styles.item}>
                  <span className={styles.itemTxt}>{item.name}</span>
                  <span className={styles.itemDots} />
                  <span className={styles.itemVal}>
                    {item?.items?.map((i, iIndex) => {
                      const suf = iIndex + 1 !== item.items?.length ? ", " : ""
                      return i.name + suf
                    })}
                  </span>
                </p>
              )
            case "boolean":
            case "checkbox":
              return (
                <p key={ind} className={styles.item}>
                  <span className={styles.itemTxt}>{item.name}</span>
                  <span className={styles.itemDots} />
                  <span className={styles.itemVal}>{t(item.value ? "yes" : "no")}</span>
                </p>
              )
            case "attachment":
              return (
                <div key={ind} className={styles.block}>
                  <p className={styles.itemTxt}>{item.name}</p>
                  {item?.files && item?.files?.length > 0 && (
                    <div className={styles.fileGrid}>
                      {item.files.map((el) => (
                        <PreviewFile key={el.id} {...el} />
                      ))}
                    </div>
                  )}
                </div>
              )
            case "photo":
              return (
                <div key={ind} className={styles.block}>
                  <p className={styles.itemTxt}>{item.name}</p>
                  {item.images && item.images?.length > 0 && (
                    <div className={styles.imgGrid}>
                      {item.images.map((el, index) => (
                        <div key={index} className={styles.imgWrap}>
                          <img src={getApiUrl() + "/public/photo/" + el} alt="" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            case "datetime":
              return (
                <p key={ind} className={styles.item}>
                  <span className={styles.itemTxt}>{item.name}</span>
                  <span className={styles.itemDots} />
                  <span className={styles.itemVal}>{moment(item?.value).format("DD.MM.YYYY kk:mm")}</span>
                </p>
              )
            default:
              // type === "text" | "number"
              return (
                <p key={ind} className={styles.item}>
                  <span className={styles.itemTxt}>{item.name}</span>
                  <span className={styles.itemDots} />
                  <span className={styles.itemVal}>{item?.value}</span>
                </p>
              )
          }
        })}

        <footer className={styles.footer}>
          <Button
            txt={t("goToReq")}
            mode={"gray"}
            onClick={() => {
              setModalOpen(true)
            }}
          />
        </footer>
      </Paper>

      {isModalOpen && (
        <OrderInfoModal
          chatID={order.chat_dialog_id}
          id={order.id}
          open={isModalOpen}
          setOpen={setModalOpen}
          layout={"active"}
        />
      )}
    </>
  )
}

export default OrderLayoutMain
