import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface ICompanyLogo {
  type: string
  data: string
}

type ParamsState = {
  isSearchVal: boolean
  companyLogo: ICompanyLogo
}

const initialState: ParamsState = {
  isSearchVal: false,
  companyLogo: {
    type: "",
    data: "",
  },
}

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setIsSearchVal: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isSearchVal = action.payload
    },
    setCompanyLogo: (state: ParamsState, action: PayloadAction<ICompanyLogo>) => {
      state.companyLogo = action.payload
    },
  },
})

export const { setIsSearchVal, setCompanyLogo } = paramsSlice.actions

export const selectParams = (state: any): ParamsState => state[paramsSlice.name]
