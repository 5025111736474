import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import clsx from "clsx"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import { StarIcon } from "../../../icons/StarIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import { useParams } from "react-router-dom"
import { IExArticle } from "../../../types/content"
import styles from "./ArticleListAccordion.module.scss"

interface Props {
  name: string
  href: string
  isArticleOpen: boolean
  isChildren?: boolean
  setAside: Dispatch<SetStateAction<IExArticle[] | null>>
  children: ReactNode
}

const TRANSITION_DURATION_MS = 200

const ArticleListAccordion = ({ name, href, isArticleOpen, isChildren = true, setAside, children }: Props) => {
  const params = useParams()
  const { favoriteArticles } = useAppSelector(selectArticles)

  const refWrap = useRef<HTMLDivElement>(null)
  const refListWrap = useRef<HTMLDivElement>(null)
  const refList = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState<boolean>(isArticleOpen)

  const toggleArticleInAsideLocal = (href: string) => {
    const articlesAsideList = localStorage.getItem("articleList")
    if (!articlesAsideList) return
    const list: IExArticle[] = JSON.parse(articlesAsideList)
    const newAside = list.map((article) => {
      // 1й элемент
      if (article.id === href) {
        article.isOpen = !article.isOpen
        return article
      }
      // 1я вложенность
      if (article.children.length > 0) {
        article.children.forEach((articleChild) => {
          if (articleChild.id === href) {
            articleChild.isOpen = !articleChild.isOpen
          } else {
            // 2я вложенность
            if (articleChild.children.length > 0) {
              articleChild.children.forEach((childItem) => {
                if (childItem.id === href) {
                  childItem.isOpen = !article.isOpen
                }
              })
            }
          }
        })
      }
      return article
    })
    localStorage.setItem("articleList", JSON.stringify(newAside))
    setAside(newAside)
  }

  const handleToggle = () => {
    if (isOpen === isArticleOpen) return
    if (!refList.current || !refWrap.current || !refListWrap.current) return
    refListWrap.current.style.overflow = "hidden"
    let height = refList.current.offsetHeight
    refListWrap.current.style.height = height + "px"

    setTimeout(() => {
      if (!refList.current || !refWrap.current || !refListWrap.current) return
      height = refList.current.offsetHeight
      refListWrap.current.style.height = isOpen ? "0px" : height + "px"
      // refListWrap.current.style.height = isArticleOpen ? "0px" : height + "px"
    }, 0)

    setTimeout(() => {
      if (!refList.current || !refWrap.current || !refListWrap.current) return
      refListWrap.current.style.overflow = ""
      refListWrap.current.style.height = ""
    }, TRANSITION_DURATION_MS)
  }

  useEffect(() => {
    setOpen(isArticleOpen)
    handleToggle()
  }, [isArticleOpen])

  return (
    <div ref={refWrap} className={clsx("accordion", styles.accordion)}>
      <div className={clsx(styles.mainItem, params?.id === href && styles["mainItem--is-active"])}>
        <button
          className={clsx("accordion__btn", styles.btn, !isChildren && styles["btn--hide"])}
          onClick={() => toggleArticleInAsideLocal(href)}
        >
          <span className={clsx("accordion__iconWrap", styles.iconWrap, isOpen && styles["iconWrap--is-open"])}>
            <ArrowRightIcon />
          </span>
        </button>

        <LocalizedLink to={`/articles/` + href} className={clsx(styles.item)}>
          <span
            className={styles.txt}
            data-tooltip-id={"globalTooltip"}
            data-tooltip-content={name}
            data-tooltip-delay-show={300}
          >
            {name}
          </span>{" "}
          {Boolean(favoriteArticles?.find((i) => i.id === href)) && <StarIcon />}
        </LocalizedLink>
      </div>
      <div ref={refListWrap} className={styles.listWrap}>
        <div ref={refList} className={styles.list}>
          {isOpen && children}
        </div>
      </div>
    </div>
  )
}

export default ArticleListAccordion
