import { FC, useEffect, useMemo, useRef, useState } from "react"
import clsx from "clsx"
import LabelCount from "../../Assets/LabelCount/LabelCount"
import { IChatInfoExt } from "../../../types/chat"
import moment from "moment"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./MessagesAsideItem.module.scss"
import { getApiUrl } from "../../../utils/getApiUrl"
import { ClipIcon } from "../../../icons/ClipIcon"

interface Props extends IChatInfoExt {
  isActive?: boolean
  onClick: (val: string, name: string, userID: string | null) => void
  className?: string
}

const MessagesAsideItem: FC<Props> = ({
  id,
  name,
  image,
  is_private,
  unreaded_messages,
  last_message,
  isActive,
  user_id,
  onClick,
  className,
}) => {
  const time = useMemo(() => {
    const date = last_message?.created_at || last_message?.readed_at
    if (!date) return null
    const isToday = new Date().toDateString() === new Date(date).toDateString()
    return isToday ? moment(date).format("kk:mm") : new Date(date).toLocaleDateString()
  }, [last_message?.created_at, last_message?.readed_at])

  const txtBlock: any = useRef()
  const txtContainer: any = useRef()

  // Стейт с подсчетом 45 символов. Немного костыльное решение
  const [txtBlockWidth, settxtBlockWidth] = useState(last_message?.text?.length > 45)

  useEffect(() => {
    if (txtBlock?.current?.clientWidth >= txtContainer?.current?.clientWidth - 20) {
      settxtBlockWidth(true)
    } else {
      settxtBlockWidth(false)
    }
  }, [txtBlock?.current?.clientWidth, txtContainer?.current?.clientWidth, last_message?.text])

  const text = useMemo(() => {
    if (!last_message?.text && !last_message?.attachments_info?.length && !last_message?.photos?.length) {
      return null
    }
    if (last_message?.text) {
      return (
        <div className={clsx(txtBlockWidth && styles["ellipsis-container"])}>
          <div
            ref={txtBlock}
            className={clsx(styles.txt, txtBlockWidth && "shortened-text")}
            dangerouslySetInnerHTML={{ __html: last_message?.text }}
          />
          {txtBlockWidth && <span className={clsx(styles.txt)}>...</span>}
        </div>
      )
    }
    if (last_message?.photos?.length) {
      return (
        <div className={styles.txt}>
          <img
            className={styles.txtImg}
            src={getApiUrl() + "/public/photo/preview/" + last_message.photos[0]}
            alt={""}
          />
        </div>
      )
    }
    if (last_message?.attachments_info?.length) {
      return (
        <div className={styles.txtClip}>
          <ClipIcon />
          <span>{last_message.attachments_info[0].filename}</span>
        </div>
      )
    }
  }, [last_message, txtBlockWidth])

  return (
    <button
      type={"button"}
      className={clsx(styles.item, isActive && styles["item--is-active"], className)}
      onClick={() => {
        onClick(id, name || "", user_id || null)
      }}
    >
      <div className={clsx(styles.imgWrap)}>
        <GetUserAvatar
          avatar={image}
          name={name}
          isOrder={!is_private}
          isPublicPreview={!is_private}
          className={clsx(styles.avatar, !image && styles["avatar--is-empty"])}
        />
        <LabelCount count={unreaded_messages} size={"sm"} className={clsx(styles.count, "count")} />
      </div>
      <div className={styles.content} ref={txtContainer}>
        <h6 className={styles.title}>
          <span className={styles.name}>{name}</span>
          {time && <span className={styles.time}>{time}</span>}
        </h6>
        {text && text}
      </div>
    </button>
  )
}

export default MessagesAsideItem
