import SimpleSelect from "../Assets/SimpleSelect/SimpleSelect"
import { useTranslation } from "react-i18next"
import { getLocale } from "../../utils/getLocale"
import { DEFAULT_LANGUAGE } from "../../utils/constants"

const SelectLang = ({ className }: { className?: string }) => {
  const currentLang = getLocale()
  const { i18n } = useTranslation("translation", { keyPrefix: `interface` })

  const onChange = (lang: { value: string; label: string; leftIcon?: string }) => {
    void i18n.changeLanguage(lang.value)
  }

  return (
    <SimpleSelect
      className={className}
      value={currentLang ?? DEFAULT_LANGUAGE}
      type={"lang"}
      list={[
        { value: "ru", label: "Русский", leftIcon: "/img/lang/ru.png" },
        { value: "en", label: "English", leftIcon: "/img/lang/en.png" },
      ]}
      onChange={onChange}
    />
  )
}

export default SelectLang
