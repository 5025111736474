// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QRScan_reader__W4yjD {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.QRScan_video__J1ieb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.QRScan_box__1fuob {
  width: 100% !important;
  left: 0 !important;
}

.QRScan_frame__CIcnw {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/QRScan/QRScan.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,4CAAA;EACA,mBAAA;AACF","sourcesContent":[".reader {\r\n  width: 100%;\r\n  height: 100%;\r\n  margin: 0 auto;\r\n  position: relative;\r\n  overflow: hidden;\r\n}\r\n\r\n.video {\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n  position: relative;\r\n}\r\n\r\n.box {\r\n  width: 100% !important;\r\n  left: 0 !important;\r\n}\r\n\r\n.frame {\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 50%;\r\n  transform: translateX(-50%) translateY(-50%);\r\n  color: var(--white);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reader": `QRScan_reader__W4yjD`,
	"video": `QRScan_video__J1ieb`,
	"box": `QRScan_box__1fuob`,
	"frame": `QRScan_frame__CIcnw`
};
export default ___CSS_LOADER_EXPORT___;
