// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoordinationFooter_coordination-footer__neAOo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
}
.CoordinationFooter_coordination-footer__neAOo textarea {
  padding: 8px;
  font-size: 12px;
  line-height: 114%;
  border: 1px solid #E0E7EB;
  border-radius: 8px;
  color: #54575C;
  background: #FFF;
  min-height: 44px;
}
.CoordinationFooter_coordination-footer__neAOo textarea.CoordinationFooter_is-error__m7kea {
  border: 1px solid var(--error);
}
.CoordinationFooter_coordination-footer__textarea-title__U2RbT {
  font-size: 16px;
  font-weight: 500;
  line-height: 114%;
}
.CoordinationFooter_coordination-footer__btn-container__ndIpp {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.CoordinationFooter_coordination-footer__btn-container__ndIpp button {
  padding: 12px 24px;
}
@media (max-width: 1200px) {
  .CoordinationFooter_coordination-footer__neAOo textarea {
    min-height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CoordinationFooter/CoordinationFooter.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,yBAAA;AACF;AACE;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACI;EACE,8BAAA;AACN;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,QAAA;EACA,yBAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAME;EACE;IACE,gBAAA;EAJJ;AACF","sourcesContent":[".coordination-footer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: flex-end;\n\n  textarea {\n    padding: 8px;\n    font-size: 12px;\n    line-height: 114%;\n    border: 1px solid #E0E7EB;\n    border-radius: 8px;\n    color: #54575C;\n    background: #FFF;\n    min-height: 44px;\n\n    &.is-error {\n      border: 1px solid var(--error);\n    }\n  }\n\n  &__textarea-title {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 114%;\n  }\n\n  &__btn-container {\n    display: flex;\n    gap: 8px;\n    justify-content: flex-end;\n\n    button {\n      padding: 12px 24px;\n    }\n  }\n\n  @media (max-width: 1200px) {\n    textarea {\n      min-height: 32px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coordination-footer": `CoordinationFooter_coordination-footer__neAOo`,
	"is-error": `CoordinationFooter_is-error__m7kea`,
	"coordination-footer__textarea-title": `CoordinationFooter_coordination-footer__textarea-title__U2RbT`,
	"coordination-footer__btn-container": `CoordinationFooter_coordination-footer__btn-container__ndIpp`
};
export default ___CSS_LOADER_EXPORT___;
