import clsx from "clsx"
import styles from "./Coordinators.module.scss"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { ProgressCircle } from "../../icons/ProgressCircle"
import { TimeLightIcon } from "../../icons/TimeLightIcon"
import { CloseCircleIcon } from "../../icons/CloseCircleIcon"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { IApprovals, IApprovalAssign } from "../../types/orderTypes"
import { Fragment } from "react"

interface Props {
  className?: string
  approveSteps?: IApprovals[]
}

const Coordinators = ({ className, approveSteps }: Props) => {
  const StatusBlock = ({ id }: { id: "waiting" | "in_progress" | "approved" | "denied" | "canceled" }) => {
    let statusInfo = ""
    let icon = undefined

    switch (id) {
      case "approved":
        statusInfo = "resolve"
        icon = <DoubleCheckIcon />
        break
      case "denied":
        statusInfo = "reject"
        icon = <CloseCircleIcon />
        break
      case "in_progress":
        statusInfo = "progress"
        icon = <ProgressCircle />
        break
      case "waiting":
        statusInfo = "wait"
        icon = <TimeLightIcon />
        break
      default:
    }

    return <div className={clsx(styles["coordinator__status"], styles[`status--${statusInfo}`])}>{icon}</div>
  }

  const isRemove = (approveStep: IApprovals, coordinator: IApprovalAssign): boolean => {
    return (
      (approveStep.type === "majority" || approveStep.type === "one") &&
      approveStep.status === "approved" &&
      coordinator.status === "canceled"
    )
  }

  return (
    <>
      <div className={clsx(className, styles["coordinators"])}>
        {approveSteps?.map((approveStep: IApprovals, i) => (
          <Fragment key={i}>
            {approveStep.assignees.map((coordinator: IApprovalAssign, k: number) => (
              <Fragment key={k}>
                {isRemove(approveStep, coordinator) ? null : (
                  <div className={styles["coordinator"]}>
                    <GetUserAvatar
                      avatar={coordinator.concordant.avatar_id}
                      name={coordinator.concordant.name}
                      className={styles["coordinator__avatar"]}
                    />
                    <div
                      className={clsx(
                        styles["coordinator__info"],
                        coordinator.status === "waiting" && styles["coordinator__disabled"],
                      )}
                    >
                      <p className={styles["coordinator__name"]}>{coordinator.concordant.name}</p>
                      {coordinator.comment && <p className={styles["coordinator__comment"]}>{coordinator.comment}</p>}
                    </div>
                    <StatusBlock id={coordinator.status} />
                  </div>
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default Coordinators
