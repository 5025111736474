// ----- Local state -----
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"
import { format, toDate } from "date-fns"
import { monthArr } from "../utils/constants"
import { timeToString } from "../utils/timeToString"
import moment from "moment"

export const useElapsedTime = (value: number, hidePrefixSuffix = false) => {
  const { i18n } = useTranslation("common", { keyPrefix: "status" })
  const { t } = useTranslation("translation", { keyPrefix: "interface" })
  const [forceUpdate, setForceUpdate] = useState<number>(0)
  const formatPattern = "dd.MM.yyyy HH:mm"
  const timeInterval = useRef<any>(false)
  // Преобразование даты к нужному виду
  const formated = format(toDate((typeof value === "number" ? value : 0) * 1000), formatPattern),
    currentMonthNum: any = formated.slice(formated.indexOf(".") + 1, formated.lastIndexOf(".")),
    tooltipDate = formated.replace(
      "." + currentMonthNum + ".",
      " " + monthArr[i18n.language][currentMonthNum - 1] + " ",
    ),
    generateDate = timeToString(
      moment,
      String(toDate((typeof value === "number" ? value : 0) * 1000)),
      t,
      hidePrefixSuffix,
    )

  useEffect(() => {
    const nowTime = moment(new Date())
    const updTime = moment(toDate((typeof value === "number" ? value : 0) * 1000))
    const diffValue = nowTime.diff(updTime, "minutes")
    if (diffValue < 60) {
      timeInterval.current = setInterval(() => {
        setForceUpdate((forceUpd: number) => forceUpd + 1)
      }, 1000 * 60)
    } else if (diffValue < 1440) {
      timeInterval.current = setInterval(
        () => {
          setForceUpdate((forceUpd: number) => forceUpd + 1)
        },
        1000 * 60 * 60,
      )
    }
    return () => {
      clearInterval(timeInterval.current)
    }
  }, [])

  return { elapsed: generateDate, date: tooltipDate }
}
