import Layout from "../../components/Layout/Layout"
import { Outlet } from "react-router-dom"
import MainLayoutLoader from "../../components/LayoutLoaders/MainLayoutLoader"

export default function ObjectPageWrap() {
  return (
    <Layout isHeaderMobHidden mainLoader={<MainLayoutLoader />}>
      <Outlet />
    </Layout>
  )
}
