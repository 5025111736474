import { Dispatch, FC, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import styles from "./Filter.module.scss"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import Button from "../Assets/Button/Button"
import { CrossIcon } from "../../icons/CrossIcon"
import Search from "../Search/Search"
import { CheckIcon } from "../../icons/CheckIcon"
import { ArrowDownIcon } from "../../icons/ArrowDownIcon"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { isArray } from "lodash"
import { declensionNumber } from "../../utils/helpers"
import moment from "moment"
import FilterModal from "../Modals/FilterModal/FilterModal"
import useWindowSize from "../../hooks/useWindowSize"
import CheckBoxRow from "../Assets/CheckBoxRow/CheckBoxRow"
import MultiDatePicker from "../MultiDatePicker/MultiDatePicker"
import _debounce from "lodash/debounce"
import _get from "lodash/get"

interface Props {
  text?: string
  className?: string
  content: "list" | "date" | "members"
  numerals?: string[]
  value?: any[] | any
  selectedValues: any[]
  isSearch?: boolean
  setChangeFilter: Dispatch<SetStateAction<any[]>>
  maxDate?: Date
  noBtn?: boolean
  titleModal?: string
  apiGetValues?: { func: any; resKey: string; endKey: string; searchKeyStr?: string }
  isFetching?: boolean
  isUninitialized?: boolean
  isLoading?: boolean
  apiLimit?: number
  isTranslate?: boolean
}

interface IFilterContent {
  localSelectedValue: any[]
  setLocalSelectedValue: Dispatch<SetStateAction<any[]>>
  list: any[]
  setList: Dispatch<SetStateAction<any[]>>
  resetFilter: () => void
  changeGlobalFilter: () => void
  content: "list" | "date" | "members"
  isSearch?: boolean
  maxDate?: Date
  noBtn?: boolean
  apiGetValues?: { func: any; resKey: string; endKey: string; searchKeyStr?: string }
  isFetching?: boolean
  isUninitialized?: boolean
  isLoading?: boolean
  apiLimit?: number
  open?: boolean
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  isListEnd: boolean
  setListEnd: Dispatch<SetStateAction<boolean>>
  apiOffset: number
  setApiOffset: Dispatch<SetStateAction<number>>
  isTranslate?: boolean
}

const FilterContent: FC<IFilterContent> = ({
  isSearch,
  content,
  maxDate,
  noBtn,
  apiGetValues,
  isFetching,
  isUninitialized,
  localSelectedValue,
  setLocalSelectedValue,
  list,
  setList,
  resetFilter,
  isLoading,
  apiLimit = 20,
  changeGlobalFilter,
  open,
  searchValue,
  setSearchValue,
  isListEnd,
  setListEnd,
  apiOffset,
  setApiOffset,
  isTranslate,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const loaderRef = useRef<HTMLDivElement>(null)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)

  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)

  const changeListActive = (el: any) => {
    if (localSelectedValue.find((selectedVal: any) => selectedVal.id === el.id)) {
      setLocalSelectedValue([...localSelectedValue.filter((selectedVal: any) => selectedVal.id !== el.id)])
    } else {
      setLocalSelectedValue((prev: any) => [...prev, el])
    }
  }

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (isUninitialized) return
      if (isSearchLoading) return
      setSearchLoading(true)
      setList([])
      fetchData(true, str)
    }, 500),
    [isUninitialized],
  )

  const fetchData = async (isStart?: boolean, str?: string) => {
    if (isListEnd) return

    if (apiGetValues) {
      setApiOffset((prev: any) => (isStart ? 0 : prev + 1))
      await apiGetValues
        .func({
          limit: apiLimit,
          offset: isStart ? 0 : (apiOffset + 1) * apiLimit,
          [apiGetValues.searchKeyStr ?? "search"]: str ?? "",
        })
        .then((res: any) => {
          setSearchLoading(false)

          if (res?.data) {
            // @ts-ignore
            setList((prev: any[]) => [...prev, ..._get(res.data, apiGetValues.resKey)] || [])
            setListEnd(_get(res.data, apiGetValues.endKey))
          }
        })
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      if (apiGetValues && !list.length) {
        fetchData(true)
      }
    } else {
      if (apiGetValues && searchValue) {
        setApiOffset(0)
        debounceFn(searchValue)
      }
    }
  }, [searchValue])

  useEffect(() => {
    if (isUninitialized) return

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0]
        if (target.isIntersecting) {
          if (isFetching || isSearchLoading) return

          void fetchData(false)
        }
      },
      { root: listContainerRef.current },
    )

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isUninitialized, isLoading, isSearchLoading, list, open, isFetching])

  return (
    <>
      {isSearch && <Search searchVal={searchValue} setSearchVal={setSearchValue} className={styles.search} />}
      {content === "date" ? (
        <>
          <div className={styles["datepicker-container"]}>
            <MultiDatePicker
              positionType="absolute"
              isDefaultOpen
              initialVals={{ planed_start_date: localSelectedValue[0], planed_end_date: localSelectedValue[1] }}
              isTwoCalendars={isDesktop}
              maxDate={maxDate}
              onChangeMainDate={(dateValues: any) => {
                setLocalSelectedValue([dateValues[0], dateValues[1]])
              }}
              pickerIsRange
            />
          </div>
        </>
      ) : (
        <div className={styles["list-container"]} ref={listContainerRef}>
          {list.length
            ? list.map((el: any) => {
                const isActive = localSelectedValue.find((selectedVal: any) => {
                  return selectedVal.id === el.id
                })

                return (
                  <div key={el.id} className={styles["item-container"]}>
                    <div className={styles["item"]} onClick={() => changeListActive(el)}>
                      {content === "members" && (
                        <GetUserAvatar
                          avatar={el.avatar_id}
                          name={isTranslate ? t(el.name) : el.name}
                          className={styles["avatar"]}
                        />
                      )}
                      {isTranslate ? t(el.name) : el.name}
                      {isDesktop && isActive && (
                        <div className={styles["check-icon"]}>
                          <CheckIcon />
                        </div>
                      )}
                    </div>
                    {!isDesktop && (
                      <CheckBoxRow
                        name={el.id}
                        value={el.id}
                        onChange={() => changeListActive(el)}
                        checked={isActive}
                        className={styles["checkbox"]}
                      />
                    )}
                  </div>
                )
              })
            : !isSearchLoading && !isFetching && <p className={styles["no-results"]}>{t("noResults")}</p>}
          {apiGetValues && (
            <div ref={loaderRef} className={clsx(styles.nothingListContainer)}>
              {(isSearchLoading || isFetching || !isListEnd) &&
                [...Array(3)].map((_item, index) => (
                  <div key={index} className={clsx(styles.skeleton, "skeletonBlock")} />
                ))}
            </div>
          )}
        </div>
      )}
      {!noBtn && (
        <div className={clsx(styles["btn-container"], "btn-container")}>
          <Button txt={t("reset")} mode={"gray"} className={styles.btn} onClick={resetFilter} />
          <Button txt={t("apply")} className={styles.btn} onClick={changeGlobalFilter} />
        </div>
      )}
    </>
  )
}

// value - Только для значений, которые прокидываются в данный компонент, а не получаются по АПИ
const Filter: FC<Props> = ({
  text,
  className,
  selectedValues,
  isSearch,
  content,
  value,
  setChangeFilter,
  numerals,
  maxDate,
  noBtn,
  titleModal,
  apiGetValues,
  isFetching,
  isUninitialized,
  isLoading,
  apiLimit = 20,
  isTranslate,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop, size } = useWindowSize()
  const wrapRef = useRef<HTMLDivElement>(null)
  const contentWrapRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState<boolean>(false)
  const [isActive, setIsActive] = useState(false)

  const [maxChangeLeftPos, setMaxChangeLeftPos] = useState<number>()
  const [searchValue, setSearchValue] = useState<string>("")
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [apiOffset, setApiOffset] = useState<number>(0)

  const [localSelectedValue, setLocalSelectedValue] = useState<any>(selectedValues)
  const [list, setList] = useState<any>(apiGetValues ? [] : value)

  useEffect(() => {
    if (content === "date") {
      if (selectedValues && !!selectedValues[0]) {
        setIsActive(true)
      }
    } else {
      if (isArray(selectedValues) && selectedValues.length > 0) {
        setIsActive(true)
      }
    }
  }, [selectedValues])

  useOnClickOutside(wrapRef, () => {
    if (open && isDesktop) closeModal()
  })

  const closeModal = () => {
    setOpen(false)
    setLocalSelectedValue(selectedValues)
    setMaxChangeLeftPos(undefined)

    if (content !== "date") {
      if (searchValue && !selectedValues.length) {
        setList([])
        setSearchValue("")
        setListEnd(false)
      }
    }
  }

  const resetFilter = () => {
    if (content !== "date") {
      setSearchValue("")
      setListEnd(false)
      setList(value ?? [])
    }
    if (selectedValues.length) setChangeFilter([])
    setLocalSelectedValue([])
    setOpen(false)
    setIsActive(false)
  }

  useEffect(() => {
    // Изменение положения фильтра, если он не влезает

    if (open) {
      const clientRect = contentWrapRef.current?.getBoundingClientRect()

      const observer = new ResizeObserver((entries) => {
        const obsRect = entries[0].contentRect

        if (contentWrapRef.current && clientRect && obsRect) {
          if (clientRect.left + obsRect.width + 25 > size[0]) {
            const changeLeftPos = clientRect.left + obsRect.width - size[0] + 25

            if (!maxChangeLeftPos) {
              setMaxChangeLeftPos(changeLeftPos)
            } else {
              setMaxChangeLeftPos((prev) => (prev ?? 0) + changeLeftPos)
            }

            contentWrapRef.current?.style.setProperty("left", `-${maxChangeLeftPos ?? changeLeftPos}px`)
          }
        }
      })

      if (contentWrapRef.current) {
        observer.observe(contentWrapRef.current)
      }

      return () => {
        contentWrapRef.current && observer.unobserve(contentWrapRef.current)
      }
    }
  }, [contentWrapRef.current, open, size[0]])

  const changeGlobalFilter = () => {
    setChangeFilter([...localSelectedValue])
    setOpen(false)
  }

  const TxtLabel = (): ReactElement => {
    const membersText = () => {
      const listActiveMembers = selectedValues

      const moreMembersArr = isArray(listActiveMembers)
        ? listActiveMembers.length < 5
          ? listActiveMembers
          : listActiveMembers.slice(0, 5)
        : []

      return (
        <>
          {content === "members" && isArray(listActiveMembers) && (
            <>
              {listActiveMembers.length > 1 ? (
                <>
                  {/* УБРАЛ ЗА НЕНАДОБНОСТЬЮ. ВЫВОД АВАТАРОК ДО 5ШТ */}
                  {/* <div
                    className={clsx(styles["members"], styles["members__more"])}
                    style={{ width: `${16 + (moreMembersArr.length - 1) * 11}px` }}
                  >
                    {moreMembersArr.map((el: any, i: number) => (
                      <div key={i} className={styles["member-container"]} style={{ right: `${i * 6}px` }}>
                        <GetUserAvatar avatar={el?.avatar_id} name={el.name} className={styles["member-avatar"]} />
                      </div>
                    ))}
                  </div> */}
                  {listActiveMembers.length}{" "}
                  {numerals && declensionNumber(Number(listActiveMembers.length), [...numerals])}
                </>
              ) : (
                <div className={clsx(styles["members"], styles["members__one"])}>
                  <GetUserAvatar
                    avatar={listActiveMembers[0].avatar_id}
                    name={listActiveMembers[0].name}
                    className={styles["member-avatar"]}
                  />
                  {listActiveMembers[0].name}
                </div>
              )}
            </>
          )}
        </>
      )
    }

    return (
      <>
        {!isActive && text}
        {isActive && (
          <>
            {content === "date" && (
              <>
                {moment.unix(selectedValues[0]).format("DD.MM.YYYY")}{" "}
                {selectedValues[1] ? <>- {moment.unix(selectedValues[1]).format("DD.MM.YYYY")}</> : null}
              </>
            )}
            {content === "members" && membersText()}
            {content === "list" &&
              selectedValues.map((el: any, i: number, arr: any) => (
                <>
                  {isTranslate ? t(el.name) : el.name}
                  {i < arr.length - 1 && ", "}
                </>
              ))}
          </>
        )}
      </>
    )
  }

  // Пропсы для FilterContent
  const filterContentProps = {
    apiLimit,
    isSearch,
    content,
    maxDate,
    noBtn,
    apiGetValues,
    isFetching,
    isUninitialized,
    localSelectedValue,
    setLocalSelectedValue,
    list,
    setList,
    resetFilter,
    isLoading,
    changeGlobalFilter,
    open,
    searchValue,
    setSearchValue,
    isListEnd,
    setListEnd,
    apiOffset,
    setApiOffset,
    isTranslate,
  }

  return (
    <>
      <div className={clsx(styles.wrap, className)} ref={wrapRef}>
        <Button
          txt={
            <span className={clsx(styles["btn__text-container"], "btn__text-container")}>
              <TxtLabel />
            </span>
          }
          mode={"white"}
          size={"sm"}
          className={clsx(styles.label, isActive && styles["label--is-active"], open && styles["label--opened"])}
          onClick={() => {
            if (!open) {
              setOpen((prev) => !prev)
            } else {
              closeModal()
            }
          }}
          icon={isActive ? <CrossIcon /> : <ArrowDownIcon />}
          onClickIcon={(e) => {
            if (isActive) {
              e.stopPropagation()
              resetFilter()
            }
          }}
        />
        <>
          {isDesktop ? (
            <>
              {open && (
                <div ref={contentWrapRef} className={clsx(styles.drop, "drop")}>
                  {<FilterContent {...filterContentProps} />}
                </div>
              )}
            </>
          ) : (
            <FilterModal
              open={open}
              setOpen={closeModal}
              content={<FilterContent {...filterContentProps} />}
              titleModal={titleModal}
            />
          )}
        </>
      </div>
    </>
  )
}

export default Filter
