import SelectObjectModal from "../Modals/SelectObjectModal/SelectObjectModal"
import { useEffect, useState } from "react"
import { IOrder, TechnicalObject } from "../../types/orderTypes"
import clsx from "clsx"
import BtnObject from "../Assets/BtnObject/BtnObject"

const SelectObject = ({
  data,
  callbackSubmit,
  className,
  error,
  initialName,
  disabled,
}: {
  data: IOrder
  callbackSubmit: (el: TechnicalObject) => void
  className?: string
  error?: any
  initialName?: string
  disabled?: boolean
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>("")

  useEffect(() => {
    if (!initialName) return
    setName(initialName)
  }, [initialName])

  return (
    <>
      <BtnObject
        type={data.service_action_name ? "button" : "div"}
        onClick={() => setModalOpen(true)}
        className={clsx(className)}
        txt={!data?.service_action_name && data.technicalObjects?.length ? data.technicalObjects[0].name : name}
        error={error}
        disabled={disabled}
      />

      <SelectObjectModal
        open={isModalOpen}
        setOpen={setModalOpen}
        serviceInfo={data}
        callbackSubmit={(el) => {
          if (!el) return
          setName(el.name)
          callbackSubmit(el)
        }}
      />
    </>
  )
}

export default SelectObject
