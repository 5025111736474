import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { Dispatch, SetStateAction, useState } from "react"
import LocalizedLink from "../../../hoc/LocalizedLink"
import SelectLang from "../../SelectLang/SelectLang"
import { useAppSelector } from "../../../hooks"
import { selectParams } from "../../../redux/slice/params"
import styles from "./OldBrowserModal.module.scss"
import Button from "../../Assets/Button/Button"

const browserList = [
  {
    name: "Google Chrome",
    img: "/img/browsers/chrome.svg",
    href: "https://www.google.cg/chrome/",
  },
  {
    name: "Mozilla Firefox",
    img: "/img/browsers/firefox.svg",
    href: "https://www.mozilla.org/firefox/new/",
  },
  {
    name: "Opera",
    img: "/img/browsers/opera.svg",
    href: "https://opera.sooftware.com/windows/download",
  },
  {
    name: "Яндекс",
    img: "/img/browsers/yandex.svg",
    href: "https://browser.yandex.ru/",
  },
]

interface Props {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const OldBrowserModal = ({ isOpen, setOpen }: Props) => {
  const { companyLogo } = useAppSelector(selectParams)

  //TODO: переводы
  return (
    <ModalPortal isOpen={isOpen} setIsOpen={setOpen} className={styles.modal}>
      {companyLogo.data && (
        <div className={styles.header}>
          <LocalizedLink to={"/"} className={styles.logo}>
            {companyLogo.type === "image/svg+xml" ? (
              <span dangerouslySetInnerHTML={{ __html: companyLogo.data }} />
            ) : (
              <img src={companyLogo.data} alt={"logo"} />
            )}
          </LocalizedLink>
          <SelectLang className={styles.lang} />
        </div>
      )}

      <p className={styles.logoTxt}>powered by Staq</p>

      <h2 className={styles.title}>К сожалению, ваш браузер устарел</h2>
      <p className={styles.txt}>
        Для быстрой и стабильной работы сайта рекомендуем установить последнюю версию одного из этих браузеров:
      </p>

      <ul className={styles.list}>
        {browserList.map((browser) => {
          return (
            <li key={browser.name} className={styles.item}>
              <a href={browser.href} target="_blank" rel="noreferrer">
                <img src={browser.img} alt={browser.name} />
                <span className={styles.itemTxt}>{browser.name}</span>
              </a>
            </li>
          )
        })}
      </ul>

      <Button txt={"Все равно продолжить"} mode={"light"} onClick={() => setOpen(false)} />
    </ModalPortal>
  )
}

export default OldBrowserModal
