import IconBtn from "../../Assets/IconBtn/IconBtn"
import { useRef, useState } from "react"
import clsx from "clsx"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import Employees from "../../Employees/Employees"
import styles from "./MessagesModalUsers.module.scss"

interface Props {
  onClick?: (userID: string, name: string, avatar_id?: string) => void
  className?: string
}

const MessagesModalUsers = ({ onClick, className }: Props) => {
  const wrapRef = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  useOnClickOutside(wrapRef, () => {
    if (isOpen) setOpen(false)
  })

  return (
    <div className={clsx(styles.wrap, className)} ref={wrapRef}>
      <IconBtn icon={"plus"} borderSize={"sm"} mode={"gray"} size={"sm"} onClick={() => setOpen((prev) => !prev)} />
      {isOpen && (
        <div className={styles.drop}>
          <Employees
            isShort
            onClick={(userID, name, avatar_id) => {
              if (onClick) onClick(userID, name, avatar_id)
              setOpen(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default MessagesModalUsers
