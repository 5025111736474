import { combineReducers } from "redux"
import { api } from "./api"
import { authSlice } from "./slice/auth"
import { languageModalSlice } from "./slice/languageModal"
import { authModalSlice } from "./slice/authModal"
import { mainSlice } from "./slice/main"
import { productsSlice } from "./slice/products"
import { objectSlice } from "./slice/object"
import { ordersSlice } from "./slice/ordersList"
import { paramsSlice } from "./slice/params"
import { articlesSlice } from "./slice/articles"
import { MobileMenuModalSlice } from "./slice/isMoreModal"
import { ModalSlice } from "./slice/modals"
import { chatSlice } from "./slice/chat"
// import { planing } from '@/redux/slices/planing'

const combineReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authSlice.name]: authSlice.reducer,
  [mainSlice.name]: mainSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
  [languageModalSlice.name]: languageModalSlice.reducer,
  [authModalSlice.name]: authModalSlice.reducer,
  [MobileMenuModalSlice.name]: MobileMenuModalSlice.reducer,
  [objectSlice.name]: objectSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [paramsSlice.name]: paramsSlice.reducer,
  [articlesSlice.name]: articlesSlice.reducer,
  [ModalSlice.name]: ModalSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
})

export default combineReducer
