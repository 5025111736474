// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Coordinators_coordinators__EOuvv {
  display: flex;
  flex-direction: column;
}

.Coordinators_coordinator__ulRak {
  padding: 6px 0;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #E0E7EB;
}
.Coordinators_coordinator__ulRak:last-child {
  border: none;
}
.Coordinators_coordinator__avatar__zwZNW {
  width: 24px !important;
  height: 24px !important;
}
.Coordinators_coordinator__status__AkTrV svg {
  fill: none;
}
.Coordinators_coordinator__info__3Hcb7 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  line-height: 114%;
}
.Coordinators_coordinator__disabled__Pdef- {
  color: #668899;
}
.Coordinators_coordinator__name__aPLmt {
  margin-top: 5px;
}
.Coordinators_coordinator__status__AkTrV {
  margin-left: auto;
  padding: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.Coordinators_status--resolve__yTPTl {
  background: #F7FCF9;
}
.Coordinators_status--resolve__yTPTl svg {
  fill: #41C061;
}
.Coordinators_status--reject__KvPR8 {
  background: #FDE8E7;
}
.Coordinators_status--progress__jP5VS {
  background: #E8F3FC;
}
.Coordinators_status--wait__xr6WR {
  background: #EFF3F5;
}`, "",{"version":3,"sources":["webpack://./src/components/Coordinators/Coordinators.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,QAAA;EACA,gCAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,sBAAA;EACA,uBAAA;AAAJ;AAII;EACE,UAAA;AAFN;AAME;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;AAJJ;AAOE;EACE,cAAA;AALJ;AAQE;EACE,eAAA;AANJ;AASE;EACE,iBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAPJ;;AAYE;EACE,mBAAA;AATJ;AAWI;EACE,aAAA;AATN;AAYE;EACE,mBAAA;AAVJ;AAYE;EACE,mBAAA;AAVJ;AAYE;EACE,mBAAA;AAVJ","sourcesContent":[".coordinators {\n  display: flex;\n  flex-direction: column;\n}\n\n.coordinator {\n  padding: 6px 0;\n  display: flex;\n  gap: 8px;\n  border-bottom: 1px solid #E0E7EB;\n\n  &:last-child {\n    border: none;\n  }\n\n  &__avatar {\n    width: 24px!important;\n    height: 24px!important;\n  }\n\n  &__status {\n    svg {\n      fill: none;\n    }\n  }\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    font-size: 12px;\n    line-height: 114%;\n  }\n\n  &__disabled {\n    color: #668899;\n  }\n\n  &__name {\n    margin-top: 5px;\n  }\n\n  &__status {\n    margin-left: auto;\n    padding: 2px;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 35px;\n  }\n}\n\n.status {\n  &--resolve {\n    background: #F7FCF9;\n\n    svg {\n      fill: #41C061;\n    }\n  }\n  &--reject {\n    background: #FDE8E7;\n  }\n  &--progress {\n    background: #E8F3FC;\n  }\n  &--wait {\n    background: #EFF3F5;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coordinators": `Coordinators_coordinators__EOuvv`,
	"coordinator": `Coordinators_coordinator__ulRak`,
	"coordinator__avatar": `Coordinators_coordinator__avatar__zwZNW`,
	"coordinator__status": `Coordinators_coordinator__status__AkTrV`,
	"coordinator__info": `Coordinators_coordinator__info__3Hcb7`,
	"coordinator__disabled": `Coordinators_coordinator__disabled__Pdef-`,
	"coordinator__name": `Coordinators_coordinator__name__aPLmt`,
	"status--resolve": `Coordinators_status--resolve__yTPTl`,
	"status--reject": `Coordinators_status--reject__KvPR8`,
	"status--progress": `Coordinators_status--progress__jP5VS`,
	"status--wait": `Coordinators_status--wait__xr6WR`
};
export default ___CSS_LOADER_EXPORT___;
