import { useTranslation } from "react-i18next"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import clsx from "clsx"
import Button from "../../Assets/Button/Button"
import { useCheckTokenMutation, useSetNewUserNameMutation } from "../../../redux/api/auth"
import { useAppDispatch } from "../../../hooks"
import { authModalOpen } from "../../../redux/slice/authModal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import { getLocale } from "../../../utils/getLocale"
import styles from "./AuthModal2.module.scss"

interface Props {
  setStep: Dispatch<SetStateAction<number>>
  setOpen: Dispatch<SetStateAction<boolean>>
}

const AuthModalReg = ({ setStep, setOpen }: Props) => {
  const dispatch = useAppDispatch()
  const { allowScroll } = useScrollBlock()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = getLocale()

  const [setNewUserName, { isLoading }] = useSetNewUserNameMutation()
  const [checkToken] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })

  const [privacyLink, setPrivacyLink] = useState<string>("https://staq.ru/terms-en.html")
  const [inputName, setInputName] = useState<string | undefined>(undefined)
  const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)

  useEffect(() => {
    setPrivacyLink(`https://staq.ru/terms-${currentLang}.html`)
  }, [currentLang])

  const loginWithCode = () => {
    if (!inputName?.length) {
      setIsErrCode(4)
      return
    }
    setNewUserName({ name: inputName.trim() }).then(() => {
      checkToken()
      // if (appendFcn) appendFcn()
      setOpen(false)
      allowScroll()
      setTimeout(() => {
        dispatch(authModalOpen({ flag: false }))
        setStep(1)
      }, 0)
    })
  }

  return (
    <>
      <h2 className={styles.title}>{t("singUp")}</h2>
      <p className={styles.txt}>{t("signUpDescr")}</p>

      <div className={clsx("modal-input", styles.inputWrap)}>
        <div className={`input-label`}>{t("yourName")}</div>
        <input
          type="text"
          value={inputName}
          onChange={(e) => {
            setInputName(e?.target?.value)
            isErrCode === 4 && setIsErrCode(undefined)
          }}
          placeholder={t("placeholderName")}
          className={`input ${isErrCode === 4 && "error"} ${styles.input}`}
        />
        {isErrCode === 4 ? <div className={clsx("error-text", "error-text_visible")}>{t("signUpError")}</div> : null}
      </div>

      <div className={clsx("modal-text", styles.txtPrivacy)}>
        {t("signUpPrivacyText")}
        <a href={privacyLink} className={"modal-text__link"} target="__blank">{`${t("signUpPrivacyLink")}`}</a>
      </div>

      <div className={clsx(styles.authBottom)}>
        <Button mode={"gray"} size={"sm-width"} txt={t("back")} onClick={() => setStep(1)} className={styles.btnBack} />
        <Button
          txt={t("confirmBtn")}
          className={styles.btnAuth}
          onClick={loginWithCode}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    </>
  )
}

export default AuthModalReg
