import Layout from "../components/Layout/Layout"
import EmployeesLayoutLoader from "../components/LayoutLoaders/EmployeesLayoutLoader"
import Employees from "../components/Employees/Employees"
import { useAppSelector } from "../hooks"
import { selectIsAllowUsersInLK } from "../redux/slice/auth"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function EmployeesPage() {
  const navigate = useNavigate()
  const isAllowUsersInLK = useAppSelector(selectIsAllowUsersInLK)

  useEffect(() => {
    if (!isAllowUsersInLK) navigate("/")
  }, [isAllowUsersInLK])

  return (
    <Layout mainLoader={<EmployeesLayoutLoader />} isHeaderMobHidden>
      <Employees />
    </Layout>
  )
}
