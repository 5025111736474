import styles from "./UploadBox.module.scss"
import { FC } from "react"
import { useDropzone } from "react-dropzone"
import { getApiUrl } from "../../../utils/getApiUrl"
import uploadFile from "../../../utils/upload/uploadFile"
import clsx from "clsx"
import { ClipIcon } from "../../../icons/ClipIcon"

interface Props {
  className?: string
  updatePhotoArr: any
  updateAttachmetsArr: any
  setFocusedInput: any
  scrollToBottomChat: any
  setIsLoading: any
}

const UploadBox: FC<Props> = ({
  className,
  updatePhotoArr,
  updateAttachmetsArr,
  setFocusedInput,
  scrollToBottomChat,
  setIsLoading,
}) => {
  const reader: any = new FileReader()
  const siteUrl = getApiUrl()?.replace("/api/v1", "") ?? window?.location?.origin

  const onDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      setIsLoading(true)
      reader.readAsDataURL(file)
      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        uploadFile({
          file: reader.result,
          reader: file,
          updatePhotoArr,
          updateAttachmetsArr,
          setIsLoading,
          scrollToBottomChat,
          siteUrl,
        })
      }
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onFileDialogOpen: () => {
      setFocusedInput(true)
    },
    onFileDialogCancel: () => {
      setFocusedInput(false)
    },
  })

  return (
    <div
      {...getRootProps({
        className: clsx(styles.box, className),
      })}
    >
      <div className={`${styles.box__icon}`}>
        {/*<img src="/img/clip.svg" alt="" />*/}
        <ClipIcon />
      </div>
      <input type="file" {...getInputProps()} />
    </div>
  )
}

export default UploadBox
