import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getApiUrl } from "../../utils/getApiUrl"
import { IServiceOrderShort } from "../../types/content"
import moment from "moment/moment"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { ArrowBoldRightIcon } from "../../icons/ArrowBoldRightIcon"
import useWindowSize from "../../hooks/useWindowSize"
import styles from "./OrderItem.module.scss"

interface Props extends IServiceOrderShort {
  clickFcn?: () => void
  ratingFn?: () => void
  layout?: "history" | "active"
}

const OrderItem: FC<Props> = ({
  photos,
  name,
  path_name,
  created_at,
  clickFcn,
  count,
  layout,
  ratingFn,
  id,
  status,
  hasReview,
  message_counts,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [wasReview, setWasReview] = useState<boolean>(false)

  const { ordersList } = useSelector((state: any) => state.ordersList)

  useEffect(() => {
    const order = ordersList.find((el: any) => el.id === id)
    if (order) {
      setWasReview(order.hasReview)
    }
  })

  const leaveFeedback = useMemo(() => {
    if (layout === "active" || !ratingFn || wasReview || hasReview || status !== 1) return null
    return (
      <button
        className={styles.ratingBtn}
        onClick={(e) => {
          e.stopPropagation()
          ratingFn()
        }}
      >
        {isDesktop ? t("giveFeedback") : t("giveRating")}
        <span className={styles.ratingBtn__iconWrap}>
          <ArrowBoldRightIcon />
        </span>
      </button>
    )
  }, [layout, isDesktop, hasReview])

  return (
    <div className={styles.orderItem} onClick={clickFcn}>
      <div className={styles.inner}>
        <div className={styles["content-container"]}>
          <div className={clsx(styles.imgWrap, !photos?.length && styles["imgWrap--empty"])}>
            {photos?.length > 0 ? (
              <img src={getApiUrl() + "/public/photo/preview/" + photos[0]} alt="" />
            ) : (
              <WorkListIcon />
            )}
          </div>
          <div className={styles.info}>
            <p className={styles.name}>
              {name} {count > 1 ? <span>x{count}</span> : null}
            </p>
            <span className={styles.description}>{path_name}</span>
            {isDesktop && leaveFeedback}
            {status === -1 && <div className={styles.canceled}>{t("canceledOrder")}</div>}
          </div>
        </div>
        <div className={clsx(styles.time, layout === "history" && styles["time-for-history"])}>
          {moment(created_at).format("kk:mm")}
        </div>
        {!isDesktop && leaveFeedback}
      </div>
      {!!message_counts && <span className={`${styles.message}`}>{message_counts}</span>}
    </div>
  )
}

export default OrderItem
