import { MouseEvent } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector } from "../../hooks"
import { selectAuth } from "../../redux/slice/auth"
import LocalizedLink from "../../hoc/LocalizedLink"
import { getApiUrl } from "../../utils/getApiUrl"
import clsx from "clsx"
import styles from "./CategoryItem.module.scss"

interface Props {
  id: string
  title?: any
  subtitle?: any
  background?: any
  img?: string
  className?: string
}

const CategoryItem = ({ id, title, subtitle, background, img, className }: Props) => {
  const { object_id } = useParams()
  const user = useAppSelector(selectAuth)

  return (
    <LocalizedLink
      to={object_id ? `/object/${object_id}/category/${id}` : `/category/${id}`}
      style={{ background: background }}
      className={clsx(styles.item, className)}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (!user?.user?.id) e.preventDefault()
      }}
    >
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.wrap}>
        <div className={styles.txtWrap}>
          <p
            className={styles.txt}
            dangerouslySetInnerHTML={{
              __html: subtitle,
            }}
          ></p>
        </div>
        {img && <img src={img ? getApiUrl() + "/public/photo/" + img : ""} className={styles.img} alt="" />}
      </div>
    </LocalizedLink>
  )
}

export default CategoryItem
