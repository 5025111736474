import clsx from "clsx"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IOrderWithoutReview, IReview } from "../../types/content"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { LInkIcon } from "../../icons/LinkIcon"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import { Dispatch, SetStateAction, useState } from "react"
import useWindowSize from "../../hooks/useWindowSize"
import UserAvatars from "../Assets/UserAvatars/UserAvatars"
import styles from "./Review.module.scss"

interface Props extends IReview {
  setReviews: Dispatch<
    SetStateAction<{
      orders: IOrderWithoutReview[]
      reviews: IReview[]
    }>
  >
  className?: string
}

const Review = ({
  order_id,
  order_name,
  chat_dialog_id,
  text,
  created_at,
  assigneeUsers,
  rating,
  setReviews,
  className,
}: Props) => {
  const { isDesktop } = useWindowSize()

  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false)

  return (
    <>
      <div
        className={clsx(styles.item, className)}
        onClick={() => {
          if (!isDesktop) setOpenOrderModal(true)
        }}
      >
        <h4 className={styles.title}>
          <span>{order_name}</span>
          {!isDesktop && (
            <StarsRating
              className={styles.stars}
              rating={rating}
              size={"sm"}
              name={"review-rate-" + order_id}
              disabled
            />
          )}
          {isDesktop && (
            <button type={"button"} className={styles.btnLink} onClick={() => setOpenOrderModal(true)}>
              <LInkIcon />
            </button>
          )}
          {!isDesktop && <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>}
        </h4>
        {text && <p className={styles.txt}>{text}</p>}
        <div className={clsx(styles.assignees, !isDesktop && styles["assignees--more"])}>
          {isDesktop ? (
            <>
              {assigneeUsers?.map((assignee) => {
                return (
                  <div key={assignee.id} className={styles.assignee}>
                    <GetUserAvatar
                      avatar={assignee?.avatar_id}
                      name={assignee?.name}
                      className={styles.avatar}
                      size={isDesktop ? "md" : "sm"}
                    />
                    <div className={styles.content}>
                      <p className={styles.name}>{assignee.name}</p>
                      <StarsRating
                        rating={rating}
                        size={"sm"}
                        name={"review-rate-" + order_id + "-" + assignee.id}
                        disabled
                      />
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <UserAvatars items={assigneeUsers} className={styles.avatars} />
              <div>
                {assigneeUsers?.map((assignee, index) => {
                  if (index > 4) return
                  const suf = index + 1 !== assigneeUsers.length ? ", " : ""
                  const more = index === 4 && assigneeUsers.length > 5 ? `еще ${assigneeUsers.length - 5}..` : ""
                  return (
                    <span key={assignee.id} className={styles.name}>
                      {assignee.name}
                      {suf}
                      <span>{more}</span>
                    </span>
                  )
                })}
              </div>
            </>
          )}
          {isDesktop && <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>}
        </div>
      </div>

      {openOrderModal && chat_dialog_id && (
        <OrderInfoModal
          chatID={chat_dialog_id}
          id={order_id}
          open={openOrderModal}
          setOpen={setOpenOrderModal}
          layout={"history"}
          delFromList={(orderId) => {
            setReviews((prev) => {
              return {
                orders: [...prev.orders],
                reviews: [...prev.reviews.filter((review) => review.order_id !== orderId)],
              }
            })
          }}
        />
      )}
    </>
  )
}

export default Review
