import { FC, Fragment, useEffect } from "react"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import ConditionList from "./ConditionList"
import { TOrderOptions } from "../../../types/orderTypes"

interface IOptionsList {
  optionsItems: TOrderOptions
  setError?: (val: boolean) => void
  optionsRef?: any
  setValidateIds: any
}

const OptionsList: FC<IOptionsList> = ({ optionsItems, setError, optionsRef, setValidateIds }) => {
  useEffect(() => {
    const requiredItems = optionsItems.filter((el) => el.required)
    const isRequairedIds = requiredItems?.map((ell) => ell.id)

    setValidateIds((pr: any[]) => {
      return [...pr, ...requiredItems]
    })

    return () => {
      if (isRequairedIds?.length) {
        setValidateIds((pr: any[]) => {
          return [...pr.filter((el) => !isRequairedIds.includes(el.id))]
        })
      }
    }
  }, [])

  useEffect(() => {
    if (optionsRef?.current) {
      optionsRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [optionsRef])

  return (
    <>
      {Array.isArray(optionsItems) && optionsItems.length > 0 ? (
        <div ref={optionsRef} className="options-list">
          {optionsItems.map((el) => (
            <Fragment key={el.id}>
              <OrderModalCommonBlock el={el} setError={setError} />
              {el?.conditions?.length > 0 && (
                <ConditionList
                  onlyDate={el.type === "datetime" && el?.only_date}
                  maxNumber={el.type === "number" && el?.max}
                  minNumber={el.type === "number" && el?.min}
                  elType={el.type}
                  elId={el.id}
                  conditionList={el?.conditions}
                  setValidateIds={setValidateIds}
                />
              )}
            </Fragment>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default OptionsList
