// Перевод времени в текстовый формат: 1 день назад, через 2 дня и т.д.

const getEndingDate = (time: number, arrText: any[]) => {
  return arrText[time % 100 > 4 && time % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][time % 10 < 5 ? time % 10 : 5]]
}

export const timeToString = (moment: any, time: string, t: any, hidePrefixSuffix = false) => {
  const nowTime = moment(new Date()).unix()
  const updTime = moment(time).unix()
  const tDifference = nowTime - updTime
  const isFuture = tDifference < 0
  const fullYears = Math.floor((isFuture ? tDifference * -1 : tDifference) / (3600 * 24 * 365))
  const fullDays = Math.floor((isFuture ? tDifference * -1 : tDifference) / (3600 * 24))
  const fullHours = Math.floor((isFuture ? tDifference * -1 : tDifference) / 3600)
  const fullMinutes = Math.floor((isFuture ? tDifference * -1 : tDifference) / 60)
  const prefix = isFuture && !hidePrefixSuffix ? t("afterAgo") + " " : null
  const suffix = !isFuture && !hidePrefixSuffix ? " " + t("ago") : ""

  if (updTime) {
    if (fullYears) {
      const aText = [t("year1"), t("year2"), t("year3")]
      return (
        <>
          {prefix}
          <b>{fullYears}</b>
          {` ${getEndingDate(fullYears, aText)}${suffix}`}
        </>
      )
    } else if (fullDays) {
      const aText = [t("day1"), t("day2"), t("day3")]
      return (
        <>
          {prefix}
          <b>{fullDays}</b>
          {` ${getEndingDate(fullDays, aText)}${suffix}`}
        </>
      )
    } else if (fullHours) {
      const aText = [t("hour1"), t("hour2"), t("hour3")]
      return (
        <>
          {prefix}
          <b>{fullHours}</b>
          {` ${getEndingDate(fullHours, aText)}${suffix}`}
        </>
      )
    } else if (fullMinutes) {
      const aText = [t("minutes1"), t("minutes2"), t("minutes3")]
      return (
        <>
          {prefix}
          <b>{fullMinutes}</b>
          {` ${getEndingDate(fullMinutes, aText)}${suffix}`}
        </>
      )
    } else {
      return `${t("justNow")}`
    }
  } else {
    return null
  }
}
