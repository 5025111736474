import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationRu from "../locales/ru.json"
import translationEn from "../locales/en.json"
import translationTh from "../locales/th.json"
// import translationFr from "../locales/fr.json"
// import translationCn from "../locales/cn.json"

const resources = {
  en: {
    translation: translationEn,
  },
  ru: {
    translation: translationRu,
  },
  th: {
    translation: translationTh,
  },
  // fr: {
  //   translation: translationFr,
  // },
  // cn: {
  //   translation: translationCn,
  // },
}

const DETECTION_OPTIONS = {
  order: ["localStorage"],
  caches: ["localStorage"],
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // fallbackLng: currentLang ? currentLang[0] : "ru",
    debug: false,
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false,
    },
  })

i18n.languages = ["ru", "en", "th"]

export default i18n
