import { Dispatch, SetStateAction, useEffect, useState } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import Button from "../../Assets/Button/Button"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import QRScan from "../../QRScan/QRScan"
import { useTranslation } from "react-i18next"
import styles from "./QrScanModal.module.scss"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const QrScanModal = ({ open, setOpen }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [isQrActive, setQrActive] = useState<boolean>(false)
  const { allowScroll } = useScrollBlock()

  useEffect(() => {
    setTimeout(() => {
      setQrActive(true)
    }, 0)
  }, [])

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      header={<h2 className={clsx("modal__title")}>{t("qr.scan")}</h2>}
      className={styles.modal}
    >
      <p className={styles.description}>
        <span>{t("qr.pointCameraAtQr")}</span>
      </p>
      <div className={styles.qrWrap}>{isQrActive && <QRScan />}</div>

      <footer className={styles.footer}>
        <Button
          txt={t("back")}
          mode={"gray"}
          onClick={() => {
            allowScroll(true)
            setOpen(false)
          }}
          className={styles.btnBack}
        />
      </footer>
    </ModalPortal>
  )
}

export default QrScanModal
