export const LInkIcon = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0996 0.900391H5.49961V2.10039H8.05108L4.07535 6.07613C3.84103 6.31044 3.84103 6.69034 4.07535 6.92465C4.30966 7.15897 4.68956 7.15897 4.92387 6.92465L8.89961 2.94892V5.50039H10.0996V0.900391Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09961 2.00039C4.09961 1.66902 3.83098 1.40039 3.49961 1.40039C1.78753 1.40039 0.399609 2.78831 0.399609 4.50039V6.00039C0.399609 6.0341 0.399601 6.06734 0.399593 6.10012C0.399409 6.93422 0.399289 7.47315 0.522276 7.93214C0.855158 9.17447 1.82553 10.1448 3.06786 10.4777C3.52685 10.6007 4.06578 10.6006 4.89988 10.6004C4.93266 10.6004 4.9659 10.6004 4.99961 10.6004H6.49961C8.21169 10.6004 9.59961 9.21247 9.59961 7.50039C9.59961 7.16902 9.33098 6.90039 8.99961 6.90039C8.66824 6.90039 8.39961 7.16902 8.39961 7.50039C8.39961 8.54973 7.54895 9.40039 6.49961 9.40039H4.99961C4.03101 9.40039 3.66441 9.39524 3.37844 9.31861C2.55022 9.09669 1.90331 8.44978 1.68139 7.62156C1.60476 7.33559 1.59961 6.96899 1.59961 6.00039V4.50039C1.59961 3.45105 2.45027 2.60039 3.49961 2.60039C3.83098 2.60039 4.09961 2.33176 4.09961 2.00039Z"
      />
    </svg>
  )
}
