// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterModal_modal__tifzT {
  padding: 12px;
  align-items: flex-end;
}
.FilterModal_modal__tifzT .modal__inner {
  max-width: 560px;
  height: 520px;
}
.FilterModal_modal__tifzT .modal__header {
  border-bottom: 0;
  padding-bottom: 0;
  min-height: auto;
}
.FilterModal_modal__tifzT .modal__body {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.FilterModal_modal__tifzT .searchBlock {
  margin: 0 0 16px;
}
.FilterModal_modal__tifzT .searchBlock input {
  padding-left: 16px;
}
@media (max-width: 1200px) {
  .FilterModal_modal__tifzT .searchBlock input {
    height: 40px;
  }
}
@media (max-width: 640px) {
  .FilterModal_modal__tifzT .searchBlock input {
    height: 30px;
  }
}
.FilterModal_modal__tifzT .btn-container {
  margin-top: auto;
  gap: 8px;
}
.FilterModal_modal__tifzT .btn-container button {
  padding: 16px 30px;
  width: 100%;
}
.FilterModal_modal__tifzT .btn-container button:first-child {
  max-width: 150px;
}
@media (max-width: 640px) {
  .FilterModal_modal__tifzT .btn-container button:first-child {
    max-width: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/FilterModal/FilterModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;AACF;AAGM;EACE,gBAAA;EACA,aAAA;AADR;AAIM;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFR;AAKM;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AAHR;AAOI;EACE,gBAAA;AALN;AAOM;EACE,kBAAA;AALR;AAMQ;EAFF;IAGI,YAAA;EAHR;AACF;AAIQ;EALF;IAMI,YAAA;EADR;AACF;AAKI;EACE,gBAAA;EACA,QAAA;AAHN;AAKM;EACE,kBAAA;EACA,WAAA;AAHR;AAKQ;EACE,gBAAA;AAHV;AAKU;EAHF;IAII,gBAAA;EAFV;AACF","sourcesContent":[".modal {\n  padding: 12px;\n  align-items: flex-end;\n\n  :global {\n    .modal {\n      &__inner {\n        max-width: 560px;\n        height: 520px;\n      }\n\n      &__header {\n        border-bottom: 0;\n        padding-bottom: 0;\n        min-height: auto;\n      }\n\n      &__body {\n        height: calc(100% - 44px);\n        display: flex;\n        flex-direction: column;\n        overflow: auto;\n      }\n    }\n\n    .searchBlock {\n      margin: 0 0 16px;\n\n      input {\n        padding-left: 16px;\n        @media (max-width: 1200px) {\n          height: 40px;\n        }\n        @media (max-width: 640px) {\n          height: 30px;\n        }\n      }\n    }\n\n    .btn-container {\n      margin-top: auto;\n      gap: 8px;\n\n      button {\n        padding: 16px 30px;\n        width: 100%;\n\n        &:first-child {\n          max-width: 150px;\n\n          @media (max-width: 640px) {\n            max-width: 100px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `FilterModal_modal__tifzT`
};
export default ___CSS_LOADER_EXPORT___;
