import Layout from "../components/Layout/Layout"
import EmployeesLayoutLoader from "../components/LayoutLoaders/EmployeesLayoutLoader"
import Coordinations from "../components/Coordinations/Coordinations"

export default function CoordinationsPage() {
  return (
    <Layout mainLoader={<EmployeesLayoutLoader />} isHeaderMobHidden>
      <Coordinations />
    </Layout>
  )
}
