export const QRIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75 7.375C6.75 7.02982 7.02982 6.75 7.375 6.75H8.625C8.97018 6.75 9.25 7.02982 9.25 7.375V8.625C9.25 8.97018 8.97018 9.25 8.625 9.25H7.375C7.02982 9.25 6.75 8.97018 6.75 8.625V7.375Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27539 6.1249C4.27539 5.10318 5.10366 4.2749 6.12539 4.2749H9.87539C10.8971 4.2749 11.7254 5.10318 11.7254 6.1249V9.8749C11.7254 10.8966 10.8971 11.7249 9.87539 11.7249H6.12539C5.10366 11.7249 4.27539 10.8966 4.27539 9.8749V6.1249ZM6.12539 5.4749C5.76641 5.4749 5.47539 5.76592 5.47539 6.1249V9.8749C5.47539 10.2339 5.76641 10.5249 6.12539 10.5249H9.87539C10.2344 10.5249 10.5254 10.2339 10.5254 9.8749V6.1249C10.5254 5.76592 10.2344 5.4749 9.87539 5.4749H6.12539Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8754 5.4751C15.2068 5.4751 15.4754 5.20647 15.4754 4.8751V3.6251C15.4754 1.91301 14.0875 0.525098 12.3754 0.525098H11.1254C10.794 0.525098 10.5254 0.793727 10.5254 1.1251C10.5254 1.45647 10.794 1.7251 11.1254 1.7251H12.3754C13.4247 1.7251 14.2754 2.57576 14.2754 3.6251V4.8751C14.2754 5.20647 14.544 5.4751 14.8754 5.4751Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8754 10.5249C15.2068 10.5249 15.4754 10.7935 15.4754 11.1249V12.3749C15.4754 14.087 14.0875 15.4749 12.3754 15.4749H11.1254C10.794 15.4749 10.5254 15.2063 10.5254 14.8749C10.5254 14.5435 10.794 14.2749 11.1254 14.2749H12.3754C13.4247 14.2749 14.2754 13.4242 14.2754 12.3749V11.1249C14.2754 10.7935 14.544 10.5249 14.8754 10.5249Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.12559 5.4751C0.794215 5.4751 0.525586 5.20647 0.525586 4.8751V3.6251C0.525586 1.91301 1.9135 0.525098 3.62559 0.525098H4.87559C5.20696 0.525098 5.47559 0.793727 5.47559 1.1251C5.47559 1.45647 5.20696 1.7251 4.87559 1.7251H3.62559C2.57624 1.7251 1.72559 2.57576 1.72559 3.6251V4.8751C1.72559 5.20647 1.45696 5.4751 1.12559 5.4751Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.12559 10.5249C0.794215 10.5249 0.525586 10.7935 0.525586 11.1249V12.3749C0.525586 14.087 1.9135 15.4749 3.62559 15.4749H4.87559C5.20696 15.4749 5.47559 15.2063 5.47559 14.8749C5.47559 14.5435 5.20696 14.2749 4.87559 14.2749H3.62559C2.57624 14.2749 1.72559 13.4242 1.72559 12.3749V11.1249C1.72559 10.7935 1.45696 10.5249 1.12559 10.5249Z"
      />
    </svg>
  )
}
