export const TimeLightIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.99935" cy="7.99935" r="5.83333" stroke="#668899" />
      <path
        d="M11 7.99935H8.16667C8.07462 7.99935 8 7.92473 8 7.83268V5.66602"
        stroke="#668899"
        strokeLinecap="round"
      />
    </svg>
  )
}
