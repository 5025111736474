// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInput_TextInputContainer__-ouSG {
  display: flex;
  position: relative;
  align-items: center;
}

.TextInput_TextInput__Ocbh9 {
  width: 100%;
  font-size: var(--fz-md);
  line-height: 144%;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #F0F1F5;
  transition: 0.2s;
}
.TextInput_TextInput__Ocbh9::placeholder {
  color: rgba(84, 87, 92, 0.4);
}
.TextInput_TextInput__Ocbh9:hover {
  border-color: rgba(64, 132, 248, 0.471);
}
.TextInput_TextInput__filled__3Fs95 {
  background-color: #F0F1F5;
}

.TextInput_icon__aJo9I {
  position: absolute;
  right: 6px;
  pointer-events: none;
}

.TextInput_close-icon__CybK4 {
  pointer-events: all;
  cursor: pointer;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.TextInput_close-icon__CybK4 svg {
  width: 12px;
  height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/TextInput/TextInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,4BAAA;AACJ;AAEE;EACE,uCAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,UAAA;EACA,oBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ","sourcesContent":[".TextInputContainer {\n  display: flex;\n  position: relative;\n  align-items: center;\n}\n\n.TextInput {\n  width: 100%;\n  font-size: var(--fz-md);\n  line-height: 144%;\n  border-radius: 4px;\n  padding: 6px;\n  border: 1px solid #F0F1F5;\n  transition: .2s;\n\n  &::placeholder {\n    color: rgba(84, 87, 92, 0.4);\n  }\n\n  &:hover {\n    border-color: rgba(64, 132, 248, .471);\n  }\n\n  &__filled {\n    background-color: #F0F1F5;\n  }\n}\n\n.icon {\n  position: absolute;\n  right: 6px;\n  pointer-events: none;\n}\n\n.close-icon {\n  pointer-events: all;\n  cursor: pointer;\n  width: 20px;\n  height: 100%;\n  display: flex;\n  align-items: center;\n\n  svg {\n    width: 12px;\n    height: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextInputContainer": `TextInput_TextInputContainer__-ouSG`,
	"TextInput": `TextInput_TextInput__Ocbh9`,
	"TextInput__filled": `TextInput_TextInput__filled__3Fs95`,
	"icon": `TextInput_icon__aJo9I`,
	"close-icon": `TextInput_close-icon__CybK4`
};
export default ___CSS_LOADER_EXPORT___;
