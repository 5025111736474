import { IChatMessage } from "../../types/chat"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IChat {
  id: string
  name: string
  messages: IChatMessage[]
}

type IState = {
  allMessage: IChatMessage[]
  chats: IChat[]
}

const initialState: IState = {
  allMessage: [],
  chats: [],
}

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: IState, action: PayloadAction<IChatMessage>) => {
      state.allMessage = [...state.allMessage.filter((f) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<string[]>) => {
      state.allMessage = [
        ...state.allMessage.map((f: any) => (action?.payload?.includes(f.id) ? { ...f, is_readed: true } : f)),
      ]
    },
    setChats: (state: IState, action: PayloadAction<IChat[]>) => {
      state.chats = action.payload
    },
  },
})

export const { setAllMessage, addAllMessage, updateReadMessages, setChats } = chatSlice.actions

export const selectChat = (state: any): IState => state[chatSlice.name]
