// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GetUserAvatar_avatar__ArByW {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 158%;
  text-transform: uppercase;
  color: var(--white);
}
.GetUserAvatar_avatar--is-empty__p58xB {
  color: var(--accent-text-color);
  background-color: rgba(var(--accent-color-rgb), 0.3);
}
.GetUserAvatar_avatar--xs__Cv8SB {
  width: 22px;
  height: 22px;
}
.GetUserAvatar_avatar--sm__tCpWz {
  width: 24px;
  height: 24px;
}
.GetUserAvatar_avatar--xsm__shr4S {
  width: 28px;
  height: 28px;
}
.GetUserAvatar_avatar--smd__-TRNH {
  width: 32px;
  height: 32px;
}
.GetUserAvatar_avatar--md__-Yd2N {
  width: 34px;
  height: 34px;
}
.GetUserAvatar_avatar--xmd__lzbqA {
  width: 38px;
  height: 38px;
}
.GetUserAvatar_avatar--lg__HyAJp {
  width: 52px;
  height: 52px;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.02em;
  font-weight: 500;
}
.GetUserAvatar_avatar__ArByW img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/GetUserAvatar/GetUserAvatar.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACE,+BAAA;EACA,oDAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;AAJJ;AAOE;EACE,WAAA;EACA,YAAA;AALJ;AAQE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;AANJ;AASE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;AAPJ","sourcesContent":[".avatar {\r\n  flex-shrink: 0;\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 50%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  font-size: 10px;\r\n  line-height: 158%;\r\n  text-transform: uppercase;\r\n  color: var(--white);\r\n\r\n  &--is-empty {\r\n    color: var(--accent-text-color);\r\n    background-color: rgba(var(--accent-color-rgb), 0.3);\r\n  }\r\n\r\n  &--xs {\r\n    width: 22px;\r\n    height: 22px;\r\n  }\r\n\r\n  &--sm {\r\n    width: 24px;\r\n    height: 24px;\r\n  }\r\n\r\n  &--xsm {\r\n    width: 28px;\r\n    height: 28px;\r\n  }\r\n\r\n  &--smd {\r\n    width: 32px;\r\n    height: 32px;\r\n  }\r\n\r\n  &--md {\r\n    width: 34px;\r\n    height: 34px;\r\n  }\r\n\r\n  &--xmd {\r\n    width: 38px;\r\n    height: 38px;\r\n  }\r\n\r\n  &--lg {\r\n    width: 52px;\r\n    height: 52px;\r\n    font-size: 22px;\r\n    line-height: 25px;\r\n    letter-spacing: 0.02em;\r\n    font-weight: 500;\r\n  }\r\n\r\n  img {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    object-position: center;\r\n    border-radius: inherit;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `GetUserAvatar_avatar__ArByW`,
	"avatar--is-empty": `GetUserAvatar_avatar--is-empty__p58xB`,
	"avatar--xs": `GetUserAvatar_avatar--xs__Cv8SB`,
	"avatar--sm": `GetUserAvatar_avatar--sm__tCpWz`,
	"avatar--xsm": `GetUserAvatar_avatar--xsm__shr4S`,
	"avatar--smd": `GetUserAvatar_avatar--smd__-TRNH`,
	"avatar--md": `GetUserAvatar_avatar--md__-Yd2N`,
	"avatar--xmd": `GetUserAvatar_avatar--xmd__lzbqA`,
	"avatar--lg": `GetUserAvatar_avatar--lg__HyAJp`
};
export default ___CSS_LOADER_EXPORT___;
