import { Dispatch, SetStateAction } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import styles from "./FilterModal.module.scss"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  content?: any
  titleModal?: string
}

const FilterModal = ({ open, setOpen, content, titleModal }: Props) => {
  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={styles.modal}
      header={<h2 className={"modal__title"}>{titleModal}</h2>}
    >
      {content}
    </ModalPortal>
  )
}

export default FilterModal
