import { useCheckTokenMutation, useGetKerbTokenMutation, useLogoutApiMutation } from "../redux/api/auth"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { authModalOpen } from "../redux/slice/authModal"
import { getKeyLangMap, langWithCurrentMomentCode } from "../utils/helpers"
import { useTranslation } from "react-i18next"
import { Loader } from "../components/Assets/Loader/Loader"
import { logout, selectUser, setIsLoginPending } from "../redux/slice/auth"
import { useScrollBlock } from "../hooks/useScrollBlock"
import { deleteCookie, getCookie, getCookies } from "../utils/cookies"
import { DEFAULT_LANGUAGE, STAQ_VERSION_COOKIE } from "../utils/constants"
import { getLocale } from "../utils/getLocale"

const WithAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [getKerbToken] = useGetKerbTokenMutation()
  const [checkToken, test] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })
  const dispatch = useAppDispatch()
  const currentLang = getLocale()
  const { i18n } = useTranslation()
  const { allowScroll } = useScrollBlock()
  const user = useAppSelector(selectUser)
  const [logoutApi] = useLogoutApiMutation()

  // const userLang = navigator.language
  // const keyLang = getKeyLangMap(userLang)

  const loginDefault = () => {
    dispatch(authModalOpen({ flag: true }))
    if (!currentLang || currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)) {
      // setTimeout(() => i18n.changeLanguage(keyLang ? keyLang[0] : "ru"), 10)
      setTimeout(() => i18n.changeLanguage(DEFAULT_LANGUAGE), 10)
    }
    dispatch(setIsLoginPending(false))
  }

  // Действия при не залогиненном пользователе
  const noAuthUser = () => {
    localStorage.clear()
    // void i18n.init({ lng: keyLang ? keyLang[0] : DEFAULT_LANGUAGE })
    void i18n.init({ lng: DEFAULT_LANGUAGE })
    getKerbToken()
      .unwrap()
      .then((response: any) => {
        if (response?.user) {
          // console.log('2')
          // allowScroll(true)
          // dispatch(authModalOpen({ flag: false }))
        } else {
          loginDefault()
        }
      })
      .catch((err) => {
        loginDefault()
      })
  }

  useEffect(() => {
    const version = getCookie(STAQ_VERSION_COOKIE)
    const allCokies = getCookies()

    if (version && version !== String(process.env.REACT_APP_PUBLIC_STAQ_VERSION)) {
      console.log("version ", version)
      console.log(
        "String(process.env.REACT_APP_PUBLIC_STAQ_VERSION) ",
        String(process.env.REACT_APP_PUBLIC_STAQ_VERSION),
      )
      for (const cookieForDelete in allCokies) {
        deleteCookie(cookieForDelete)
      }
      localStorage.clear()
      logoutApi().then(() => {
        window?.Echo?.leave(`privateUser.${user?.id}`)
        dispatch(logout())
        dispatch(authModalOpen({ flag: true }))
        document.location.assign("/")
      })
    } else {
      checkToken().then(
        (result: any) => (!result?.data?.data?.user ? noAuthUser() : null), //Если есть токен, но юзер не найден
      )
      setLoading(false)
    }
  }, [])

  return isLoading ? <Loader /> : null
}

export default WithAuth
