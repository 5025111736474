export const ProgressCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4.66602L8 2.66602" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13.334L8 12.0007" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8L13.3333 8" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.66602 8L4.66602 8" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.293 4.70703L11.7644 4.23563" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.22852 11.7715L5.17132 10.8287" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.8281 10.8281L11.7709 11.7709" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.22852 4.22852L5.64273 5.64273" stroke="#1D8AE2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
