export const CheckIcon = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50008 7.08586L11.793 0.792969L13.2072 2.20718L5.50008 9.91429L0.792969 5.20718L2.20718 3.79297L5.50008 7.08586Z"
      />
    </svg>
  )
}
