import { useEffect, useRef, useState } from "react"
import QrScanner from "qr-scanner"
import { QrAreaIcon } from "../../icons/QrAreaIcon"
// import { useNavigate } from "react-router-dom"
import styles from "./QRScan.module.scss"

const QRScan = () => {
  // const navigate = useNavigate()
  const scanner = useRef<QrScanner>()
  const videoEl = useRef<HTMLVideoElement>(null)
  const qrBoxEl = useRef<HTMLDivElement>(null)
  const [qrOn, setQrOn] = useState<boolean>(true)

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    if (result?.data) {
      window.location.replace(result.data)
      // navigate(result.data)
    }
  }

  const onScanFail = (err: string | Error) => {
    console.error(err)
  }

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      })

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false)
        })
    }

    return () => {
      // if (!videoEl?.current)
      scanner?.current?.stop()
    }
  }, [videoEl?.current, qrBoxEl?.current])

  return (
    <div className={styles.reader}>
      <video ref={videoEl} className={styles.video} />
      <div ref={qrBoxEl} className={styles.box} />
      <QrAreaIcon className={styles.frame} />
    </div>
  )
}

export default QRScan
