import { Dispatch, SetStateAction, useEffect, useState } from "react"
import styles from "./CoordinationFooter.module.scss"
import useWindowSize from "../../hooks/useWindowSize"
import TextArea from "../Assets/TextArea/TextArea"
import clsx from "clsx"
import Button from "../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import { useSetApproveCoordinationMutation } from "../../redux/api/coordinations"

interface Props {
  coordinationId?: string
  setIsViewCoordinationFooter: Dispatch<SetStateAction<boolean>>
  getOrderFunc?: () => void
}

const CoordinationFooter = ({ coordinationId, setIsViewCoordinationFooter, getOrderFunc }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [commentTxt, setCommentTxt] = useState("")
  const [commentErr, setCommentErr] = useState(false)

  const [setApprove, { isLoading }] = useSetApproveCoordinationMutation()

  const approveRejectCoordination = (type: boolean) => {
    if (!coordinationId) return
    if (!type && commentTxt.trim().length <= 0) {
      setCommentErr(true)
      return
    }

    setApprove({ id: coordinationId, isApproved: type, comment: commentTxt }).then(() => {
      setIsViewCoordinationFooter(false)
      if (getOrderFunc) getOrderFunc()
    })
  }

  useEffect(() => {
    if (commentTxt.trim().length > 0) setCommentErr(false)
  }, [commentTxt])

  return (
    <>
      <div className={styles["coordination-footer"]}>
        {!isDesktop && (
          <label className={styles["coordination-footer__textarea-title"]}>{t("commentToCoordination")}</label>
        )}
        <TextArea
          autoSize
          minRows={1}
          maxRows={isDesktop ? 3 : 2}
          placeholder={isDesktop ? t("commentToCoordination") : t("enterText")}
          onChange={(e: any) => setCommentTxt(e.target.value)}
          value={commentTxt}
          className={clsx(commentErr && styles["is-error"])}
        />
        <div className={styles["coordination-footer__btn-container"]}>
          <Button
            txt={t("reject")}
            mode={"warning"}
            className={styles.btn}
            onClick={() => approveRejectCoordination(false)}
            disabled={isLoading}
          />
          <Button
            txt={t("approve")}
            className={styles.btn}
            onClick={() => approveRejectCoordination(true)}
            disabled={isLoading}
          />
        </div>
      </div>
    </>
  )
}

export default CoordinationFooter
