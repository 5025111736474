import { useRef } from "react"

const useScrollBlock = () => {
  const scroll = useRef(false)

  const blockScroll = () => {
    if (typeof document === "undefined") return

    const html = document.documentElement
    const { body } = document
    const header = document.querySelector(".header__wp") as HTMLDivElement

    if (!body || !body.style || scroll.current) return

    const scrollBarWidth = window.innerWidth - html.clientWidth
    const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0
    const scrollWidth = `${bodyPaddingRight + scrollBarWidth}px`

    html.style.position = "relative"
    body.style.position = "relative"
    html.style.overflow = "hidden"
    body.style.overflow = "hidden"
    body.style.paddingRight = scrollWidth
    if (header) header.style.marginRight = scrollWidth

    scroll.current = true
  }

  const allowScroll = (isFullClose?: boolean) => {
    if (typeof document === "undefined") return

    const html = document.documentElement
    const { body } = document
    const header = document.querySelector(".header__wp") as HTMLDivElement

    if (isFullClose) scroll.current = true
    if (!body || !body.style || !scroll.current) return

    html.style.position = ""
    html.style.overflow = ""
    body.style.position = ""
    body.style.overflow = ""
    body.style.paddingRight = ""
    if (header) header.style.marginRight = ""

    scroll.current = false
  }

  return { blockScroll, allowScroll }
}

export { useScrollBlock }
