// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/img/chat_bg-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessagesModalLoader_loader__o3fNR {
  width: 100%;
  min-height: 592px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .MessagesModalLoader_loader__o3fNR {
    border-bottom-right-radius: 16px;
  }
}

.MessagesModalLoader_mesLoader__oEfau {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex: 1 1;
  padding: 20px 16px 8px 16px;
}

.MessagesModalLoader_listLoader__Rc3uQ {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}
.MessagesModalLoader_listLoader__avatar__K28OS {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 4px;
}
.MessagesModalLoader_listLoader__txt__VQ0xK {
  border-radius: 8px;
  width: 150px;
  height: 60px;
}
.MessagesModalLoader_listLoader--reverse__vLiWZ {
  justify-content: flex-end;
}

.MessagesModalLoader_footerLoader__kzYBw {
  min-height: 59px;
  border-top: 1px solid #E0E7EB;
  flex-shrink: 0;
}
@media (min-width: 1200px) {
  .MessagesModalLoader_footerLoader__kzYBw {
    min-height: var(--modal-footer-height);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/MessagesModal/MessagesModalLoader/MessagesModalLoader.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EAPF;IAQI,gCAAA;EAEF;AACF;;AACA;EACE,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,SAAA;EACA,2BAAA;AAEF;;AACA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAEF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAEJ;AACE;EACE,kBAAA;EACA,YAAA;EACA,YAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,6BAAA;EACA,cAAA;AADF;AAGE;EALF;IAMI,sCAAA;EAAF;AACF","sourcesContent":[".loader {\r\n  width: 100%;\r\n  min-height: 592px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: hidden;\r\n\r\n  @media (min-width: 1200px) {\r\n    border-bottom-right-radius: 16px;\r\n  }\r\n}\r\n\r\n.mesLoader {\r\n  background-image: url('../../../../../public/img/chat_bg-2.png');\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  background-position: center;\r\n  flex: 1;\r\n  padding: 20px 16px 8px 16px;\r\n}\r\n\r\n.listLoader {\r\n  display: flex;\r\n  align-items: flex-end;\r\n  margin-bottom: 12px;\r\n\r\n  &__avatar {\r\n    width: 28px;\r\n    height: 28px;\r\n    border-radius: 50%;\r\n    margin-right: 4px;\r\n  }\r\n\r\n  &__txt {\r\n    border-radius: 8px;\r\n    width: 150px;\r\n    height: 60px;\r\n  }\r\n\r\n  &--reverse {\r\n    justify-content: flex-end;\r\n  }\r\n}\r\n\r\n.footerLoader {\r\n  min-height: 59px;\r\n  border-top: 1px solid #E0E7EB;\r\n  flex-shrink: 0;\r\n\r\n  @media (min-width: 1200px) {\r\n    min-height: var(--modal-footer-height);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `MessagesModalLoader_loader__o3fNR`,
	"mesLoader": `MessagesModalLoader_mesLoader__oEfau`,
	"listLoader": `MessagesModalLoader_listLoader__Rc3uQ`,
	"listLoader__avatar": `MessagesModalLoader_listLoader__avatar__K28OS`,
	"listLoader__txt": `MessagesModalLoader_listLoader__txt__VQ0xK`,
	"listLoader--reverse": `MessagesModalLoader_listLoader--reverse__vLiWZ`,
	"footerLoader": `MessagesModalLoader_footerLoader__kzYBw`
};
export default ___CSS_LOADER_EXPORT___;
